import React from 'react'
import { FiAlertOctagon } from "react-icons/fi";
import { MdOutlineAutorenew } from 'react-icons/md'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

type StatusType = 'Pending Review' | 'In Progress' | 'Complete'
type SizeType = 'xs' | 'sm' | 'md'

interface StatusBadgeProps {
    status: StatusType
    size?: SizeType
}

/**
 * StatusBadge Component
 * @param {StatusBadgeProps} props - Component props.
 * @returns {JSX.Element} Styled status badge component.
 */
const StatusBadge: React.FC<StatusBadgeProps> = ({
    status,
    size = 'md',
}: StatusBadgeProps): JSX.Element => {
    const statusConfig: Record<
        StatusType,
        { bgColor: string; textColor: string; icon: JSX.Element; label: string }
    > = {
        'Pending Review': {
            bgColor: 'bg-[rgba(245,193,69,0.14)]',
            textColor: 'text-americanOrange',
            icon: <FiAlertOctagon />,
            label: 'Pending Review',
        },
        'In Progress': {
            bgColor: 'bg-aliceBlue',
            textColor: 'text-trueBlue',
            icon: <MdOutlineAutorenew />,
            label: 'In Progress',
        },
        Complete: {
            bgColor: 'bg-brightGreen',
            textColor: 'text-goGreen',
            icon: <IoMdCheckmarkCircleOutline />,
            label: 'Complete',
        },
    }

    const sizeConfig: Record<
        SizeType,
        { height: string; iconSize: number; textSize: string }
    > = {
        xs: {
            height: 'h-6', // 28px
            iconSize: 14,
            textSize: 'text-xs',
        },
        sm: {
            height: 'h-7', // 28px
            iconSize: 16,
            textSize: 'text-xs',
        },
        md: {
            height: 'h-8', // 32px
            iconSize: 18,
            textSize: 'text-xs',
        },
    }

    const { bgColor, textColor, icon, label } = statusConfig?.[status] ||  statusConfig?.["Pending Review"]
    const { height, iconSize, textSize } = sizeConfig[`${size}`]

    return (
        <div
            className={`flex flex-row items-center px-2 py-1 font-medium rounded-lg ${bgColor} ${textColor} ${height} ${textSize} gap-2 w-max`}
        >
            {React.cloneElement(icon, { size: iconSize })}
            {label}
        </div>
    )
}

export default StatusBadge
