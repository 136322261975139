// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setReviewTabSelect } from '@/store'
import ReviewTab from '@/modules/review-2/review/ReviewTab'
import CommentsTab from '@/modules/review-2/review/CommentsTab'

const panelTabs = [
    { type: 'review', label: 'Review' },
    { type: 'comments', label: 'Comments' },
]


const ComponentType = {
    MONOPOLE: 'Pole: Monopole',
    HFRAME: 'Pole: H-Frame',
    LATTICE: 'Pole: Lattice',
    INSULATOR: 'Insulator',
    CROSSARM: 'Cross Arm',
    TRANSFORMER: 'Transformer',
    ARRESTOR: 'Arrestor',
    GROUNDWIRE: 'Ground Wire',
    GUYWIRE: 'Guy Wire',
    CONDUCTOR: 'Conductor',
    STATICWIRE: 'Static Wire',
    M_POLE_SILVER_STEEL: 'm_pole_silver_steel',
    M_POLE_WOOD: 'm_pole_wood',
    M_POLE_WEATHERED_STEEL: 'm_pole_weathered_steel',
    HF_SILVER_STEEL: 'hf_silver_steel',
    HF_WOOD: 'hf_wood',
};

const ReviewRightPanel = () => {
    const dispatch = useDispatch()
    const { reviewTabSelect } = useSelector((state: RootState) => state.review)

    return (
        <div className="flex flex-row w-full h-full font-roboto pt-4">
            <div className="flex flex-col w-full mr-4 h-full">
                <div className="flex flex-row items-center w-full py-2  px-4 gap-4 bg-white rounded-lg">
                    {panelTabs.map((tab) => {
                        const isActive = reviewTabSelect === tab.type
                        // Common styles for all buttons
                        const baseClasses =
                            'flex items-center justify-center text-center font-medium px-4 py-2.5 h-[40px] text-sm font-roboto cursor-pointer rounded-md'

                        // Conditional styles based on active state
                        const activeClasses = isActive
                            ? 'text-trueBlue bg-aliceBlue'
                            : 'text-arsenic'
                        return (
                            <div
                                key={tab.type}
                                className={`${baseClasses} ${activeClasses}`}
                                onClick={() => {
                                    dispatch(setReviewTabSelect(tab.type))
                                }}
                            >
                                {tab.label}
                            </div>
                        )
                    })}
                   
                </div>
                {reviewTabSelect === 'comments' ? <CommentsTab /> : <ReviewTab />}
            </div>
        </div>
    )
}

export default ReviewRightPanel
