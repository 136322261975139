// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import BaseService from '@/services/BaseService'

type ViewMode = "table" | "map";

interface dashboardState {
    defects: any[]
    originalDefects: any[];
    viewMode: ViewMode
    loadingDefects: boolean
    locationMap: any[] | null
    searchMap: string | null
}

const initialState: dashboardState = {
    defects: [],
    originalDefects: [],
    viewMode: "table",
    loadingDefects: false,
    locationMap: null,
    searchMap: ""
}

export const getDefects = createAsyncThunk(
    '/dashboard/all',
    async (_, { rejectWithValue }) => {
        const param = {
            url: '/dashboard/all',
            method: 'get',
        }
        try {
            const response: AxiosResponse = await BaseService(param)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    },
)

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setViewMode: (state, action: PayloadAction<string>) => {
            state.viewMode = action.payload
        },
        setLocationMap: (state, action: PayloadAction<string>) => {
            state.locationMap = action.payload
        },
        setSearchMap: (state, action: PayloadAction<string>) => {
            state.searchMap = action.payload;

            if (!action.payload) {
                state.defects = state.originalDefects;
            } else {
                const searchText = action.payload.toLowerCase();
                state.defects = state.originalDefects.filter((defect) => {
                    const assetCode = defect?.assetCode?.toLowerCase() || '';
                    const type = defect?.type?.toLowerCase() || '';
                    return assetCode.includes(searchText) || type.includes(searchText);
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDefects.pending, (state) => {
                state.loadingDefects = true
            })
            .addCase(getDefects.fulfilled, (state, action) => {
                state.loadingDefects = false
                state.originalDefects = action.payload;
                state.defects = action.payload
            })
            .addCase(getDefects.rejected, (state) => {
                state.loadingDefects = false
            })
    },
})

export const { setViewMode, setLocationMap, setSearchMap } = dashboardSlice.actions

export default dashboardSlice.reducer
