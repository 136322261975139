export const BASE_URL = "/dashboard-2"


export const DefectType = {
    Lean: 'Lean',
    Rot: 'Rot',
    Cracks: 'Cracks',
    Splices: 'Splices',
    Broken: 'Broken',
    Leaking: 'Leaking',
    Damage: 'Damage',
    Loose: 'Loose',
    StructureClassification: 'Structure Classification',
};

export const detectionTypes = [
    { key: 'structureClassification', value: 'Structure Classification' },
    { key: 'wireDetection', value: 'Wire Detection' },
    { key: 'insulatorDetection', value: 'Insulator Detection' },
    { key: 'transformerDetection', value: 'Transformer Detection' },
    { key: 'serviceDrops', value: 'ServiceDrops' },
];

export function getKeyByType(valueToFind: any) {
    const result = detectionTypes.find(item => item.value === valueToFind);
    return result?.key || null;
}
