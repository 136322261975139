import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { RootState } from '@/store/rootReducer';
import BaseService from '@/services/BaseService'


interface NotificationState {
    notifications: any[];
    loading: boolean;
}

const initialState: NotificationState = {
    notifications: [],
    loading: false,
};

// Notificationları getiren servis
export const fetchNotifications = createAsyncThunk(
    '/micro-fe/notifications',
    async (_, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            const token = state.global?.token;
            const tenantId = state.global?.tenantId;
          

            if (!token || !tenantId) {
                throw new Error('Missing required authentication data');
            }

            const param = {
                url: `/micro-fe/notifications?&tenantId=${tenantId}&token=${token}`,
                method: 'get',
                params: {},
            }

            const response: AxiosResponse = await BaseService(param)
            return response.data.notifications

        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to fetch notifications');
        }
    }
);

// Notification okundu olarak işaretleme servisi
export const markNotificationAsRead = createAsyncThunk(
    '/micro-fe/notification-set-read',
    async ({ id }: { id: string }, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            const token = state.global?.token;
            const tenantId = state.global?.tenantId;
          

            if (!token || !tenantId) {
                throw new Error('Missing required authentication data');
            }

            const param = {
                url: `/micro-fe/notification-set-read`,
                method: 'put',
                data: {notificationId:id, tenantId, token},
            }

            const response: AxiosResponse = await BaseService(param)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to mark notification as read');
        }
    }
);

// Bildirim aksiyonunu işleyen servis
export const handleNotificationAction = createAsyncThunk(
    'notification/handleAction',
    async ({ notification, actionTaken }: { notification: any, actionTaken: string }, { getState, rejectWithValue, dispatch }) => {
        try {
            const state = getState() as RootState;
            const token = state.global?.token;
            const tenantId = state.global?.tenantId;
          
            // Önce okunmadıysa bildirim okundu olarak işaretle
            if (!notification.read) {
                await dispatch(markNotificationAsRead({ id: notification.id }));
            }

        
            if (notification.actionTaken !== actionTaken) {
                const param = {
                    url: `/micro-fe/notification-action-taken`,
                    method: 'put',
                    data: { notificationId: notification.id, actionTaken, token, tenantId }
                };
                await BaseService(param);
                await dispatch(fetchNotifications());
            }
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to handle notification action');
        }
    }
);

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload;
            })
            .addCase(fetchNotifications.rejected, (state) => {
                state.loading = false;
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                state.notifications = state.notifications.map(notification =>
                    notification.id === action.payload ? { ...notification, read: true } : notification
                );
            })
            .addCase(handleNotificationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(handleNotificationAction.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(handleNotificationAction.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default notificationSlice.reducer; 