// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useRef, useState} from 'react'

import {
    MdArrowBack,
    MdArrowForwardIos, MdCheckCircleOutline,
    MdOutlineLocationOn,
} from 'react-icons/md'
import {RootState, setReviewData, setReviewMainImage, setReviewShow, setSelectedTable} from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button as PButton } from 'primereact/button'
import {CiShare2} from "react-icons/ci";

const ReviewTitle = () => {
    const dispatch = useDispatch()

    const [iconCopy, setIconCopy] = useState(
        <CiShare2 className="font-medium" />,
    )
    const [buttonTooltip, setButtonTooltip] = useState<string>('Click to copy')
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null) // Define the type for timeoutRef

    const { selectedTable, reviewData } = useSelector((state: RootState) => state.review)


    const goBack = () => {
        dispatch(setReviewShow(false))
    }

    const formattedInspectionTime = dayjs(reviewData?.assetDetail?.inspections[0].inspectionTime).format(
        'MM/DD/YYYY',
    )
    const formattedTime = dayjs(reviewData?.assetDetail?.inspections[0].inspectionTime).format(
        'hh:mm A [+UTC]',
    )
    const separator = (
        <div className="flex w-1 h-1 rounded-full bg-graniteGray"></div>
    )

    const handleShare = () => {
        const currentUrl = window.location.href
        navigator.clipboard
            .writeText(currentUrl)
            .then(() => {
                setIconCopy(<MdCheckCircleOutline className="font-medium" />)
                setIsCopied(true)
                setButtonTooltip('Copied!')

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }
                // Set a new timeout to reset the tooltip after 1 seconds
                timeoutRef.current = setTimeout(() => {
                    setIconCopy(<CiShare2 className="font-medium" />)
                    setIsCopied(false)
                    setButtonTooltip('Click to copy')
                }, 1000) // Reset tooltip after 1 seconds
            })
            .catch((err) => {
                console.error('Failed to copy URL:', err)
            })
    }

    /**
     * Resets the tooltip text when the mouse leaves the button.
     * This ensures that the tooltip displays "Click to copy" after the user moves the cursor away.
     */
    const handleMouseLeave = () => {
        setIsCopied(false)
        setButtonTooltip('Click to copy')
    }

    return (
        <div className="flex justify-between bg-white pt-2 pl-4 items-center font-roboto">
            <div className="flex flex-col grow font-roboto">
                <div className=" flex flex-row items-center gap-1 mt-0.5">
                    <div
                        className="bg-brightGray px-2 py-1 rounded-md cursor-pointer"
                        onClick={goBack}
                    >
                        <MdArrowBack className="text-arsenic" size={20} />
                    </div>
                    <div className="text-maastrichtBlue text-[22px] font-normal">
                        {reviewData?.assetDetail?.asset?.code}
                    </div>
                </div>
                <div className="flex flex-row w-full items-center text-xs mt-1.5 mb-1.5 text-arsenic font-normal gap-1 flex-wrap">
                    <div className="flex flex-row items-center">
                        <MdOutlineLocationOn className="text-graniteGray" />
                        {reviewData?.assetDetail?.asset?.address}
                        <div className="text-trueBlue flex flex-row ml-1">
                            {reviewData?.assetDetail?.asset?.lon} - {reviewData?.assetDetail?.asset?.lat}
                        </div>
                    </div>
                    {separator}
                    <div className="flex flex-row flex-wrap text-arsenic">
                        Inspection date: {formattedInspectionTime},{' '}
                        <span className="text-graniteGray ml-1.5">
                            <i> {formattedTime}</i>
                        </span>
                    </div>
                    {separator}
                    <div className="flex flex-row items-center">
                        Inspector: {reviewData?.assetDetail?.inspections[0]?.createdBy?.name}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-2 mt-1 mr-4">
                
            </div>
        </div>
    )
}

export default ReviewTitle
