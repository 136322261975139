// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { combineReducers, CombinedState, AnyAction, Reducer } from '@reduxjs/toolkit'
import dashboard, { dashboardState } from '@/store/slices/dashboard/dashboardSlice'
import review, { reviewState } from '@/store/slices/dashboard/reviewSlice'
import comment, { commentState } from '@/store/slices/dashboard/commentSlice'
import global, { GlobalState } from '@/store/slices/dashboard/globalSlice'
import notification, { notificationState } from '@/store/slices/dashboard/notificationSlice'
import RtkQueryService from '@/services/RtkQueryService'

export type RootState = CombinedState<{
    dashboard: dashboardState
    review: reviewState
    comment: commentState
    global: GlobalState
    notification: notificationState
    [RtkQueryService.reducerPath]: ReturnType<typeof RtkQueryService.reducer>
}>

export interface AsyncReducers {
    [key: string]: Reducer
}

const staticReducers = {
    dashboard,
    review,
    comment,
    global,
    notification,
    [RtkQueryService.reducerPath]: RtkQueryService.reducer,
}

function rootReducer(asyncReducers?: AsyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers,
    }) as Reducer<RootState, AnyAction>
}

export default rootReducer
