// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react'
import { Image } from 'primereact/image'
import { useDispatch, useSelector } from 'react-redux'
import { getThumbBlobName} from '@/utils'
import { RootState, setSelectedImage} from '@/store'
import { Skeleton } from 'primereact/skeleton'
import useStorageAccess from '@/hooks/useStorageAccess'

export default function ReviewThumbs() {
    const dispatch = useDispatch()
    const storageAccess = useStorageAccess()
    const {
        reviewData,
        selectedTable,
        selectedImage
    } = useSelector((state: RootState) => state.review)

    const [reviewImageThumbs, setReviewImageThumbs] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    /**
     * Blob URL create
     */
    const getBlobUrl = (blobName: string) => {
        if (!storageAccess.token) return null;
        const { storageName, token } = storageAccess;
        return token.replace('/?', `/${storageName}/${blobName}?`);
    }

 
    const handleImageSelect = (index, inspectionId) => {
        dispatch(setSelectedImage({
            index,
            inspectionId
        }));
    };

    useEffect(() => {
        setIsLoading(true);
        if (reviewData?.assetDetail && reviewData?.assetDetail?.asset?.id) {
            const filterIns = reviewData.assetDetail.inspections?.find(
                (val) => val.assetId === reviewData?.assetDetail?.asset?.id
            );
            
            if (filterIns?.images?.length) {
                const imageUrls = filterIns.images.map(image => ({
                    blobName: image.blobName,
                    url: getBlobUrl(getThumbBlobName(image.blobName))
                })).filter(img => img.url);
                
                setReviewImageThumbs(imageUrls);
                setIsLoading(false);
            } else {
                setReviewImageThumbs([]);
                setIsLoading(false);
            }
        } else {
            setReviewImageThumbs([]);
            setIsLoading(false);
        }

        return () => {
            setReviewImageThumbs([]);
            setIsLoading(false);
        };
    }, [reviewData, storageAccess]);

    return (
        <>
            <div className="grid grid-cols-6 gap-1.5 h-full w-full">
                {isLoading && (
                    <div className="col-span-7 gap-2 flex flex-row w-full h-full">
                        {Array.from({ length: 5 }, (_, index) => (
                            <Skeleton
                                key={`skeleton-${index}`}
                                width="100vw"
                                height="100px"
                                borderRadius="16px"
                            />
                        ))}
                    </div>
                )}
                {!isLoading && storageAccess.token &&
                    reviewImageThumbs.map((image, index) => {
                        return (
                            <div
                                key={image.blobName}
                                className={`relative cursor-pointer h-[100px] ${
                                    selectedImage === index ? 'selected-border' : ''
                                }`}
                                onClick={() => handleImageSelect(index, reviewData?.assetDetail?.inspections[0]?.id)}
                            >
                                <Image
                                    src={image?.url}
                                    alt="Thumb"
                                    imageClassName="w-full object-cover rounded h-full"
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    )
}
