// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useCallback, useState } from 'react'
import OverlayCommentOptions from '@/modules/review-2/review/Comment/OverlayCommentOptions'
import dayjs from 'dayjs'
import { HiUserCircle } from 'react-icons/hi'
import MentionInput from '@/modules/review-2/review/Comment/MentionInput'
import { useDispatch, useSelector } from 'react-redux'
import {deleteReplyComment, RootState, setSelectedReplyId, updateReplyComment} from '@/store'

const ReplyItemTab = ({
    comment,
    goToImage,
    userNames,
    parentCommentId,
    parentComment,
}) => {
    const dispatch = useDispatch()

    const { selectedTable, selectedImageData } = useSelector(
        (state: RootState) => state.review,
    )

    const { selectedReplyId } = useSelector(
        (state: RootState) => state.comment,
    )

    const [textMessage, setTextMessage] = useState()

    const updateMessageInput = useCallback(() => {
        return (
            <MentionInput
                value={textMessage}
                placeholder="Update comment"
                userNames={userNames}
                onSubmit={() => {
                    dispatch(
                        updateReplyComment({
                            id: comment.id,
                            message: message,
                            asset: selectedTable,
                            imageId: selectedImageData?.id,
                            commentId: parentCommentId
                        }),
                    )
                    dispatch(setSelectedReplyId(null))
                }}
                onChange={(e) => setTextMessage(e.target.value)}
            />
        )
    }, [textMessage, userNames])

    return (
        <>
            <div key={comment.id}>
                <div className="ml-6 mt-6">
                    <div className="flex flex-row">
                        <HiUserCircle
                            style={{
                                height: '24px',
                                width: '24px',
                            }}
                            className="flex-shrink-0 h-[24px] w-[24px]"
                        />
                        <div className="mb-1 flex flex-col ml-1 font-roboto content-center mt-1">
                            <span className="font-semibold text-gray-800 mb-1 text-xs">
                                {comment.createdBy.name}
                            </span>
                            <span className="text-gray-500 text-xs">
                                {dayjs(comment.commentTime).format(
                                    'DD/MM/YYYY HH:mm',
                                )}
                            </span>
                        </div>
                        <div>
                            <OverlayCommentOptions
                                onDelete={() =>
                                    dispatch(
                                        deleteReplyComment({
                                            id: comment.id,
                                            asset: selectedTable,
                                            imageId: selectedImageData?.id,
                                        }),
                                    )
                                }
                                onEdit={() => {
                                    dispatch(setSelectedReplyId(comment.id))
                                    setTextMessage(comment.message)
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex-1 ml-7 mt-2.5">
                        <p
                            className={`m-0 font-roboto text-sm break-all ${
                                parentComment.label
                                    ? 'hover:underline cursor-pointer'
                                    : ''
                            }`}
                            onClick={() => {
                                if (
                                    parentComment.label &&
                                    selectedReplyId !== comment?.id
                                )
                                    goToImage(parentComment)
                            }}
                        >
                            {selectedReplyId !== comment?.id
                                ? comment.message
                                      .split('\n')
                                      .map((text, textIndex) => (
                                          <React.Fragment key={textIndex}>
                                              {text}
                                          </React.Fragment>
                                      ))
                                : updateMessageInput()}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(ReplyItemTab)
