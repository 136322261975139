export type AppConfig = {
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    tourPath: string
    locale: string
    enableMock: boolean
}

function getBackendURL(): string {
    if (['https://blue-ground-011fd660f.4.azurestaticapps.net', 'https://dev.threev.ai'].includes(window.location.origin)) {
        return 'https://pge-threev-dev-nest-dta8g8gjbub0efdt.eastus-01.azurewebsites.net';
    }
    return 'https://threev-nest-app.azurewebsites.net';
}

console.log('NODE_ENV', process.env.NODE_ENV)
const apiPrefix = process.env.NODE_ENV == 'production' ? getBackendURL() : 'http://localhost:3000'

const appConfig: AppConfig = {
    apiPrefix,
    authenticatedEntryPath: '/dashboard-2',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export function isDevBackend() {
    return appConfig.apiPrefix.startsWith('https://pge-threev-dev-nest');
}

export default appConfig
