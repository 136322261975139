// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import '@arcgis/core/assets/esri/themes/light/main.css'
import WMSLayer from '@arcgis/core/layers/WMSLayer'
import ScaleBar from '@arcgis/core/widgets/ScaleBar'
import Zoom from '@arcgis/core/widgets/Zoom'
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle'
import ReactDOM from 'react-dom/client'
import useStorageAccess from '@/hooks/useStorageAccess'
import { getPreviewBlobName } from '@/global/utils'
import { TbFocusCentered } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import {
    setReviewShow,
    setSelectedTable,
    setReviewMainImageLoading,
    setReviewImageThumbsLoading,
    getReviewData,
    setInitialLoad
} from '@/store'
import Point from '@arcgis/core/geometry/Point'
import Popup from "@arcgis/core/widgets/Popup"

const THREEV_WMS_URL = `https://geoserver.threev.ai/geoserver/threev/ows?`

const ReviewMap = () => {
    const dispatch = useDispatch()
    const { reviewData, isInitialLoad } = useSelector((state: RootState) => state.review);

    const viewRef = useRef(null) // To store the map view reference
    const mapDiv = useRef(null)

    const storageAccess = useStorageAccess();

    /**
     * Blob URL create
     */
    const getBlobUrl = (blobName: string) => {
        if (!storageAccess.token) return null;
        const { storageName, token } = storageAccess;
        return token.replace('/?', `/${storageName}/${blobName}?`);
    }

    const statusColors = {
        "Compliant": 'bg-northTexasGreen', // Color for Compliant status
        'Non-Compliant': 'bg-cinnabar', // Color for Non-Compliant status
        'Compliant-Warning': 'bg-americanOrange', // Color for Compliant-Warning status
    }

    const statusColorsHex = {
        "Compliant": '#009F25', // Color for Compliant status
        'Non-Compliant': '#EC4830', // Color for Non-Compliant status
        'Compliant-Warning': '#FC880E', // Color for Compliant-Warning status
    }

    /**
     * Retrieves the color associated with a given status.
     *
     * @param {string} status - The status for which to get the associated color.
     * @returns {string} The color corresponding to the status, or a default color if the status is not recognized.
     */
    const getStatusColor = (status, hex = false) => {
        if (hex) {
            return statusColorsHex[status] || 'defaultColor' // Fallback color if status is not recognized
        }
        return statusColors[status] || 'defaultColor' // Fallback color if status is not recognized
    }

    /**
     * Generates the HTML content for a popup, including various data fields and an image.
     *
     * @param {Object} data - The data object containing information for the popup.
     * @param {string} [data.code] - The code value to be displayed in the popup.
     * @param {string} [data.type] - The type value to be displayed in the popup.
     * @param {string} [data.status] - The status value to be displayed in the popup.
     * @param {string} [data.subType] - The subType value to be displayed in the popup.
     * @param {number} [data.lat] - The latitude value to be displayed in the popup.
     * @param {number} [data.lon] - The longitude value to be displayed in the popup.
     * @param {string} [data.imageUrl] - The URL of the image to be displayed in the popup.
     * @returns {string} The generated HTML content for the popup.
     */
    async function getPopup(datas) {
        if (!datas) return '';

        const data = datas?.assetDetail?.asset;
        const previewBlobName = getPreviewBlobName(datas?.assetDetail?.inspections[0]?.images[0]?.blobName);
        const imageUrl = getBlobUrl(previewBlobName);

        const code = data?.code || 'N/A'
        const type = data?.type || 'Unknown Type'
        const status = data?.status || 'Unknown Status'
        const statusColor = getStatusColor(status)
        const lat = data?.lat || 'N/A'
        const lon = data?.lon || 'N/A'

        const div = document.createElement('div')
        div.innerHTML = `
          <div class="p-2 bg-white rounded-lg shadow-md flex flex-row justify-between gap-3 m-2 md:m-0">
            <div class="flex justify-between flex-col">
                <div class="flex flex-col grow">
                  <h3 class="text-lg font-bold">${code}</h3>
                  <span class="inline-block ${statusColor} text-white text-xs px-2 py-0.5 rounded mt-2 w-max">
                    ${status}
                  </span>
                  <p class="text-gray-600 mt-2">Lat / Long: ${lat}, ${lon}</p>
                  <p class="text-gray-600">${type}</p>
                  </div>
                   <div class="flex space-x-2">
                       <button id="view-details-btn" class="bg-gray-200 py-1.5 px-2 text-gray-800 rounded-lg hover:bg-gray-300">
                        Review
                      </button>
                   </div>
            </div>
            <div class="flex justify-between items-center mt-2">
                  <img alt="" class="w-40 h-40 object-cover rounded-md" src="${imageUrl}" />
            </div>
          </div>`

        // Add a click event listener to the "View Details" button
        const viewDetailsButton = div.querySelector('#view-details-btn')
        viewDetailsButton.addEventListener('click', (e) => {
            e.stopPropagation()
            dispatch(setReviewShow(true))
            dispatch(setSelectedTable(data))
            dispatch(setReviewMainImageLoading(true))
            dispatch(setReviewImageThumbsLoading(true))
        })

        return div
    }

    useEffect(() => {
        if (reviewData?.assetDetail && storageAccess) {
            (async () => {
                // Make sure the map is ready
                if (isInitialLoad && 
                    viewRef.current && 
                    reviewData.assetDetail.asset?.lon && 
                    reviewData.assetDetail.asset?.lat) {
                    try {
                        // Wait for the map to fully load
                        await viewRef.current.when();
                        
                        // Check again after map is loaded
                        if (!viewRef.current || viewRef.current.destroyed) {
                            return;
                        }

                        // Additional wait to ensure map is ready
                        await new Promise(resolve => setTimeout(resolve, 500));

                        const point = new Point({
                            longitude: reviewData.assetDetail.asset.lon,
                            latitude: reviewData.assetDetail.asset.lat,
                            spatialReference: { wkid: 4326 }
                        });

                        try {
                            // First do a simple zoom and center
                            viewRef.current.zoom = 18;
                            viewRef.current.center = point;

                            // Short delay before animated transition
                            await new Promise(resolve => setTimeout(resolve, 100));

                            await viewRef.current.goTo({
                                target: point,
                                scale: 750
                            }, {
                                duration: 1000,
                                easing: "ease-in-out"
                            });

                        } catch (error) {
                            console.warn("Navigation error:", error);
                            // Fallback: Simple position change
                            if (viewRef.current) {
                                viewRef.current.zoom = 18;
                                viewRef.current.center = point;
                            }
                        }

                        dispatch(setInitialLoad(false));
                    } catch (error) {
                        console.error("View initialization error:", error);
                    }
                }
                try {
                    const popupContent = await getPopup(reviewData);
                    if (viewRef.current && viewRef.current.popup) {
                        // Popup ayarlarını güncelleyelim
                        viewRef.current.popup.dockOptions = {
                            buttonEnabled: false,
                            breakpoint: false,
                            position: "auto"  // Otomatik konumlandırma
                        };

                        viewRef.current.popup.visibleElements = {
                            closeButton: false,
                            actionBar: false,
                            collapseButton: false,
                            featureNavigation: false,
                        }

                        const targetPoint = {
                            type: 'point',
                            longitude: reviewData.assetDetail.asset?.lon,
                            latitude: reviewData.assetDetail.asset?.lat,
                        }

                        if (targetPoint.longitude && targetPoint.latitude && viewRef.current?.popup && viewRef.current?.popup?.open) {
                            try {
                                viewRef.current.popup.open({
                                    location: targetPoint,
                                    content: popupContent,
                                });
                                
                                // Popup açıldıktan sonra konumu ayarla
                                viewRef.current.center = new Point({
                                    longitude: targetPoint.longitude,
                                    latitude: targetPoint.latitude,
                                    spatialReference: { wkid: 4326 }
                                });
                            } catch (error) {
                                console.error('Error opening popup:', error);
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error generating popup:', error);
                }
            })();
        }
    }, [reviewData, storageAccess]);

    useEffect(() => {
        if (!mapDiv.current) return

        const initialCenter = [-101.780904, 47.238593]; // Initial center coordinates
        const initialZoom = 11; // Initial zoom level

        const customLayerParameters = {
            viewparams: `tenant_id:5`,
        }

        // Create WMS layers for assets
        const linesAssetLayer = new WMSLayer({
            url: THREEV_WMS_URL,
            sublayers: [
                {
                    name: 'threev:tvw_line', // Replace with the name of your layer in GeoServer
                },
            ],
            customLayerParameters,
        })

        const poleAssetLayer = new WMSLayer({
            url: THREEV_WMS_URL,
            sublayers: [
                {
                    name: 'threev:tvw_pole', // Replace with the name of your layer in GeoServer
                },
            ],
            customLayerParameters,
        })

        // Create the map with the base layer and asset layers
        const map = new Map({
            basemap: 'streets', // base-map should.
            layers: [linesAssetLayer, poleAssetLayer],
        })

        // Create the MapView and set it to the container
        const view = new MapView({
            container: mapDiv.current,
            map: map,
            center: initialCenter,
            zoom: initialZoom,
            constraints: {
                snapToZoom: false,
            },
            ui: {
                components: [], // Disable default zoom controls
            },
            popup: new Popup({}),
            // Add navigation properties to handle wheel events
            navigation: {
                mouseWheelZoomEnabled: true,
                browserTouchPanEnabled: true
            }
        })

        // Create container for "Re-center" button
        const recenterButton = document.createElement('div');
        recenterButton.className = 'custom-recenter-button'; // Styling class
        const root = ReactDOM.createRoot(recenterButton);
        root.render(<TbFocusCentered size={20} />)

        viewRef.current = view;

        recenterButton.onclick = () => {
            view.goTo({
                center: initialCenter,
                zoom: initialZoom,
            });
        };

        // Add the Re-center button directly above the Zoom widget
        view.ui.add(recenterButton, {
            position: 'manual', // Controlled placement with CSS
        });

        const scalebar = new ScaleBar({
            view
        });
        view.ui.add(scalebar, "bottom-left")

        const zoomWidget = new Zoom({
            view
        });

        view.ui.add(zoomWidget, {
            position: "bottom-right",
        });

        // **Add BasemapToggle widget**
        const basemapToggle = new BasemapToggle({
            view: view,
            nextBasemap: "satellite", // Alternate basemap
        });

        // Position the BasemapToggle widget
        view.ui.add(basemapToggle, {
            position: "bottom-right", // Position in the bottom-left corner
        });

        // Adds a click event listener to the map view. When a click occurs, it retrieves information from a WMS service based on the map extent and the click position.
        view.on(['click'], function (event) {
            const mapExtent = view.extent
            const x = Math.round(event.x)
            const y = Math.round(event.y)
            const url =
                `${THREEV_WMS_URL}service=WMS&version=1.1.1&request=GetFeatureInfo` +
                `&layers=threev:tvw_pole&query_layers=threev:tvw_pole` +
                `&bbox=${mapExtent.xmin},${mapExtent.ymin},${mapExtent.xmax},${mapExtent.ymax}` +
                `&width=${view.width}&height=${view.height}&srs=EPSG:3857` +
                `&info_format=application/json&x=${x}&y=${y}`

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.features && data.features.length > 0) {
                        const attributes = data.features[0].properties
                        dispatch(getReviewData({ assetId: attributes?.id }))
                    }
                })
        })

        // Add passive event listeners for wheel events
        mapDiv.current.addEventListener('wheel', () => {}, { passive: true });

        return () => {
            if (view) {
                // Remove event listeners
                mapDiv.current?.removeEventListener('wheel', () => {});
                view.destroy() // Cleanup MapView on unmount
            }
        }
    }, [mapDiv, storageAccess])

    return (
        <div
            className="relative flex flex-col h-full"
            style={{ height: '100%' }}
        >
            <div ref={mapDiv} className="h-full" />
        </div>
    )
}

export default ReviewMap
