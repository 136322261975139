// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import BaseService from '@/services/BaseService'
import { getPreviewBlobName, getThumbBlobName } from '@/utils'
import { getKeyByType } from '@/store/slices/dashboard/constants'
import { RootState } from '@/store/rootReducer'

interface reviewState {
    reviewShow: boolean
    reviewTabSelect: string
    selectedTable: any[]
    reviewData: {
        assetDetail?: any;
        user?: any;
        microFeUser?: any;
    }
    reviewType: string
    reviewDataLoading: boolean
    reviewMainImage: any[]
    reviewMainImageLoading: boolean
    reviewImageThumbs: any[]
    reviewImageThumbsLoading: boolean
    selectedImage: number
    selectedImageData: any[] | null
    transmissionBlob: any[] | null
    transmissionBlobLoading: boolean
    maskImage: boolean
    isInitialLoad: boolean
}

const initialState: reviewState = {
    reviewShow: false,
    reviewTabSelect: 'review',
    selectedTable: [],
    reviewData: {
        assetDetail: undefined,
        user: undefined,
        microFeUser: undefined,
    },
    reviewType: '',
    reviewDataLoading: false,
    reviewMainImage: [],
    reviewMainImageLoading: false,
    reviewImageThumbs: [],
    reviewImageThumbsLoading: false,
    selectedImage: 0,
    selectedImageData: null,
    transmissionBlob: null,
    transmissionBlobLoading: false,
    maskImage: true,
    isInitialLoad: false
}

export const getReviewData = createAsyncThunk(
    '/micro-fe/asset-detail',
    async ({ assetId }: { assetId: string }, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            const token = state.global?.token;
            const tenantId = state.global?.tenantId;
            const id = state.global?.id;

            if (!token || !tenantId) {
                throw new Error('Missing required authentication data');
            }

            const param = {
                url: `/micro-fe/asset-detail?assetId=${assetId || id}&tenantId=${tenantId}&token=${token}`,
                method: 'get',
                params: {},
            }

            const response: AxiosResponse = await BaseService(param)
            return response.data
        } catch (error: any) {
            console.error('Error fetching review data:', error);
            return rejectWithValue(error.message || 'Failed to fetch review data')
        }
    },
)

export const getReviewImages = createAsyncThunk(
    '/storage/blob-names',
    async (
        {
            inspection,
            imageIndex,
            preview = false,
            controller,
        }: {
            inspection: any
            imageIndex: number
            preview: boolean
            controller: any
        },
        { rejectWithValue },
    ) => {
        const url = '/storage/blob-names'

        const filteredImages =
            imageIndex !== undefined
                ? inspection.images.filter(
                      (image) => image.id === inspection.images[imageIndex].id,
                  )
                : inspection.images

        const blobNames = filteredImages.map((image) =>
            preview ? image.blobName : getPreviewBlobName(image.blobName),
        )

        const param = {
            url,
            method: 'get',
            params: { blobNames },
            signal: controller?.signal,
        }
        try {
            const response: AxiosResponse = await axios.get(param)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    },
)

export const getTransmissionStructure = createAsyncThunk(
    '/transmission_structure/',
    async (
        { file, storageName, token }: { file: any },
        { rejectWithValue },
    ) => {
        const url = token.replace('/?', `/${storageName}/${file}?`)
        try {
            const response: AxiosResponse = await axios.get(url)
            return response.data?.masks
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    },
)

export const getReviewThumbsImages = createAsyncThunk(
    '/storage/blob-names/thumbnails',
    async ({ inspection }: { inspection: any }, { rejectWithValue }) => {
        const url = '/storage/blob-names'
        const param = {
            url,
            method: 'get',
            params: {
                blobNames: inspection.images.map((image) =>
                    getThumbBlobName(image.blobName),
                ),
            },
        }
        try {
            const response: AxiosResponse = await BaseService(param)
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    },
)

const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        setReviewShow: (state, action: PayloadAction<boolean>) => {
            state.reviewShow = action.payload
        },
        setReviewTabSelect: (state, action: PayloadAction<string>) => {
            state.reviewTabSelect = action.payload
        },
        setSelectedTable: (state, action: PayloadAction<any>) => {
            state.selectedTable = action.payload
        },
        setMaskImage: (state, action: PayloadAction<boolean>) => {
            state.maskImage = action.payload
        },
        setReviewMainImage: (state, action: PayloadAction<any[]>) => {
            state.reviewMainImage = action.payload
        },
        setReviewMainImageLoading: (state, action: PayloadAction<boolean>) => {
            state.reviewMainImageLoading = action.payload
        },
        setReviewImageThumbsLoading: (state, action: PayloadAction<boolean>) => {
            state.reviewImageThumbsLoading = action.payload
        },
        setReviewData: (state, action: PayloadAction<any>) => {
            state.reviewData = action.payload
        },
        setInitialLoad: (state, action: PayloadAction<boolean>) => {
            state.isInitialLoad = action.payload
        },
        setSelectedImage: (state, action: PayloadAction<{ index: number; inspectionId: string }>) => {
            state.selectedImage = action.payload.index;

            const inspection = state.reviewData?.assetDetail?.inspections?.find(
                (val) => val.id === action.payload.inspectionId
            );

            if (inspection?.images) {
                const inspectionImage = inspection.images.find(
                    (image) => image.id === inspection.images[action.payload.index].id
                );
                state.selectedImageData = inspectionImage || null;
            } else {
                state.selectedImageData = null;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReviewData.pending, (state) => {
                state.reviewDataLoading = true
            })
            .addCase(getReviewData.fulfilled, (state, action) => {
                state.reviewDataLoading = false
                state.reviewData = action.payload
                state.selectedImage = 0;
                state.selectedImageData = action.payload?.assetDetail?.inspections?.[0]?.images?.[0] || null;
                state.reviewType = getKeyByType(action.payload?.assetDetail?.inspections?.[0]?.defects?.[0]?.type)
            })
            .addCase(getReviewData.rejected, (state) => {
                state.reviewDataLoading = false
            })
        builder
            .addCase(getReviewThumbsImages.pending, (state) => {
                state.reviewImageThumbsLoading = true
            })
            .addCase(getReviewThumbsImages.fulfilled, (state, action) => {
                state.reviewImageThumbsLoading = false
                state.reviewImageThumbs = action.payload
            })
            .addCase(getReviewThumbsImages.rejected, (state) => {
                state.reviewImageThumbsLoading = false
            })
        builder
            .addCase(getReviewImages.fulfilled, (state, action) => {
                state.reviewMainImageLoading = false
                state.reviewMainImage = action.payload
            })
        builder
            .addCase(getTransmissionStructure.pending, (state, action) => {
                state.transmissionBlobLoading = true
            })
            .addCase(getTransmissionStructure.fulfilled, (state, action) => {
                state.transmissionBlobLoading = false
                state.transmissionBlob = action.payload
            })
            .addCase(getTransmissionStructure.rejected, (state) => {
                state.transmissionBlobLoading = false
            })
    },
})

export const {
    setReviewShow,
    setReviewTabSelect,
    setSelectedTable,
    setSelectedImage,
    setMaskImage,
    setReviewMainImage,
    setReviewMainImageLoading,
    setReviewImageThumbsLoading,
    setReviewData,
    setInitialLoad
} = reviewSlice.actions

export default reviewSlice.reducer
