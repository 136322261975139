export type AppConfig = {
    apiPrefix: string
    locale: string
}

function getBackendURL(): string {
    if (['https://black-hill-0fabcd40f.4.azurestaticapps.net', 'https://microui-dev.threev.ai'].includes(window.location.origin)) {
        return 'https://pge-threev-dev-nest-dta8g8gjbub0efdt.eastus-01.azurewebsites.net';
    }
    return 'https://threev-nest-app.azurewebsites.net';
}

console.log('NODE_ENV', process.env.NODE_ENV)
const apiPrefix = process.env.NODE_ENV == 'production' ? getBackendURL() : 'http://localhost:3000'

const appConfig: AppConfig = {
    apiPrefix,
    locale: 'en',
}

export default appConfig
