// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import NotificationBanner from '@/modules/review-2/general/NotificationBanner'
import { Button } from '@/components/ui'
import StatusBadge from '@/modules/review-2/general/StatusBadge'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import BaseService from '@/services/BaseService'
import RejectDialog from '@/modules/review-2/review/RejectDialog'
import AIBanner from '@/modules/review-2/general/AIBanner'

interface TransformerFormData {
    numberOfTransformers: string | null
    condition: string | null
}

interface InsulatorFormData {
    type: string | null
    material: string | null
}

interface TransmissionFormData {
    type: string | null
}

interface ServiceOption {
    label: string
    description: string
    value: string
}

interface ServiceOption {
    label: string
    value: string
    description: string
}

interface ServiceFormProps {
    serviceOptions: ServiceOption[]
    selectedService: string
    setSelectedService: (value: string) => void
    notes: string
    setNotes: (value: string) => void
    reviewType: string
}

type YesNoOption = {
    label: string
    value: string
}

const YesNoOption: YesNoOption[] = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
]

const serviceOptions: ServiceOption[] = [
    {
        label: 'Transformer to Home/Building',
        description:
            'Service drop from a transformer on the pole directly to a home building or commercial property',
        value: 'transformer',
    },
    {
        label: 'Mid-Span Service Drop',
        description:
            'Service drop from the middle of a span (the wire between two poles) directly to a home or building',
        value: 'midspan',
    },
    {
        label: 'Underground Service Entry',
        description: 'Service drop transitions underground',
        value: 'underground',
    },
    {
        label: 'Pole-to-Pole Service Transfer',
        description: 'Service drop that transfers between poles',
        value: 'poletopole',
    },
    {
        label: 'Meter Loop Service Drop',
        description: 'Service drop connecting to a meter loop',
        value: 'meterloop',
    },
    {
        label: 'Shared/Secondary Drop Drop',
        description: 'A service drop shared by multiple homes',
        value: 'shared',
    },
]

const TransmissionOptions = [
    { label: 'Pole: Monopole', value: 'Pole: Monopole' },
    { label: 'Pole: H-Frame', value: 'Pole: H-Frame' },
    { label: 'Pole: Lattice', value: 'Pole: Lattice' },
    { label: 'Insulator', value: 'Insulator' },
    { label: 'Cross Arm', value: 'Cross Arm' },
    { label: 'Transformer', value: 'Transformer' },
    { label: 'Arrestor', value: 'Arrestor' },
    { label: 'Ground Wire', value: 'Ground Wire' },
    { label: 'Guy Wire', value: 'Guy Wire' },
    { label: 'Conductor', value: 'Conductor' },
    { label: 'Static Wire', value: 'Static Wire' },
    { label: 'Steel Lattice', value: 'm_pole_silver_steel' },
    { label: 'Wooden Monopole', value: 'm_pole_wood' },
    { label: 'Weathered Monopole', value: 'm_pole_weathered_steel' },
    { label: 'Steel Monopole', value: 'hf_silver_steel' },
    { label: 'Wooden H-Frame', value: 'hf_wood' },
]

const TransformerQualityOptions = [
    { label: 'Good', value: 'good' },
    { label: 'Bad', value: 'bad' },
]

const TransformerNumberOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
]

const InsulatorMaterialOptions = [
    { label: 'Ceramic', value: 'ceramic' },
    { label: 'Glass', value: 'glass' },
    { label: 'Composite', value: 'composite' },
]

const InsulatorTypeOptions = [
    { label: 'String', value: 'string' },
    { label: 'Post', value: 'post' },
]

const ActionButtons = ({ isActive, handleSubmit, handleReject }) => (
    <div className="flex flex-row w-full gap-2 pt-2 mt-auto">
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="gray-200"
            textColor="text-arsenic"
            className="w-full"
            onClick={handleReject}
        >
            Cancel
        </Button>
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="trueBlue"
            textColor="text-white"
            className="w-full"
            disabled={isActive}
            onClick={handleSubmit}
        >
            Submit
        </Button>
    </div>
)

const ServiceForm: React.FC<ServiceFormProps> = ({
    serviceOptions,
    selectedService,
    setSelectedService,
    notes,
    setNotes,
    reviewType,
}) => (
    <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
        <div className="flex flex-col py-4 pl-4 pr-2">
            <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                {reviewType} Detected
                <StatusBadge status="Pending Review" size="sm" />
            </div>
            <div className="flex flex-col mt-5">
                <label
                    htmlFor="serviceType"
                    className="text-sm font-medium text-graniteGray mb-2"
                >
                    Service Drop Down Type
                </label>
                <Dropdown
                    id="serviceType"
                    value={selectedService}
                    options={serviceOptions}
                    onChange={(e) => setSelectedService(e.value)}
                    optionLabel="label"
                    placeholder="Select Service Type"
                    itemTemplate={(option) => (
                        <div className="flex flex-col p-2">
                            <span className="font-medium text-gray-800">
                                {option.label}
                            </span>
                            <span className="text-xs text-gray-500">
                                {option.description}
                            </span>
                        </div>
                    )}
                    className="w-full custom-dropdown"
                />
            </div>
            <div className="mt-4">
                <label
                    htmlFor="additionalNotes"
                    className="text-sm font-medium text-graniteGray mb-2"
                >
                    Additional Notes
                </label>
                <InputTextarea
                    id="additionalNotes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={5}
                    cols={30}
                    placeholder="Type to add additional notes"
                    className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                />
            </div>
            <div className="flex flex-col w-full gap-2 pt-2 mt-auto">
                <Button
                    shape="round"
                    variant="custom"
                    size="sm"
                    color="trueBlue"
                    textColor="text-white"
                    className="w-full"
                >
                    Accept Service Drop Down Detection
                </Button>
                <Button
                    shape="round"
                    variant="custom"
                    size="sm"
                    color="gray-200"
                    textColor="text-arsenic"
                    className="w-full"
                >
                    Reject Detection
                </Button>
            </div>
        </div>
    </div>
)

const WireDetectionForm: React.FC<ServiceFormProps> = ({
    onDataChange,
    status,
}) => {
    const [formData, setFormData] = useState({
        primary: '',
        secondary: '',
        neutral: '',
        jointUse: '',
        guyWires: '',
    })

    const handleChange = (field: string, value: string) => {
        const updatedData = { ...formData, [field]: value }
        setFormData(updatedData)
        onDataChange(updatedData) // Notify parent
    }

    return (
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                    Wire Detection Detected
                    {status}
                </div>
                {[
                    { label: 'Primary', field: 'primary' },
                    { label: 'Secondary', field: 'secondary' },
                    { label: 'Neutral', field: 'neutral' },
                    {
                        label: 'Joint Use (Communication Wires)',
                        field: 'jointUse',
                    },
                    { label: 'Guy Wires', field: 'guyWires' },
                ].map(({ label, field }) => (
                    <div className="flex flex-col mt-5" key={field}>
                        <label className="text-sm font-medium text-graniteGray mb-2">
                            {label}
                        </label>
                        <Dropdown
                            options={YesNoOption}
                            optionLabel="label"
                            placeholder={`Select ${label}`}
                            className="w-full custom-dropdown"
                            value={formData[field]}
                            onChange={(e) => handleChange(field, e.value)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

const ServiceDropDetectionForm: React.FC<ServiceFormProps> = ({
    onDataChange,
    status,
}) => {
    const [formData, setFormData] = useState<TransmissionFormData>({
        type: null,
    })

    const { selectedTable } = useSelector((state: RootState) => state.review)

    const handleChange = (
        field: keyof TransmissionFormData,
        value: string | null,
    ) => {
        const updatedData = { ...formData, [field]: value }
        setFormData(updatedData)
        if (onDataChange) {
            onDataChange(updatedData)
        }
    }
    useEffect(() => {
        setFormData({ type: "yes" })
    }, [selectedTable])

    const optionTemplate = (option: any) => {
        const rateText = option.value === "yes"
            ? `${(selectedTable?.rate * 100).toFixed(0)}%`
            : null; // For "No"

        return (
            <div className="flex items-center">
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg min-w-[40px] text-center">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{option.label}</span>
            </div>
        )
    }

    const valueTemplate = (option: any) => {
        if (!option) return 'Select'
        const rateText = option.value === "yes"
            ? `${(selectedTable?.rate * 100).toFixed(0)}%`
            : null; // For "No"
        return (
            <div className="flex items-center">
                <img
                    className="mr-1"
                    src={'/img/gemini-icon-2.png'}
                    style={{ width: '24px', height: '24px' }}
                    alt="star"
                />
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg min-w-[40px] text-center">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{option.label}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                    Service Drop Detected
                    {status}
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="type"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Is there a service drop down on this structure?
                    </label>
                    <Dropdown
                        id="type"
                        options={YesNoOption}
                        value={formData.type || selectedTable?.componentType}
                        optionLabel="label"
                        optionValue="value"
                        onChange={(e) => handleChange('type', e.value)}
                        placeholder="Select"
                        className="w-full custom-dropdown"
                        itemTemplate={optionTemplate}
                        valueTemplate={valueTemplate}
                    />
                </div>
            </div>
        </div>
    )
}

const TransmissionDetectionForm: React.FC<ServiceFormProps> = ({
    onDataChange,
    status,
}) => {
    const [formData, setFormData] = useState<TransmissionFormData>({
        type: null,
    })

    const { selectedTable, reviewData, reviewShow } = useSelector((state: RootState) => state.review)

    const handleChange = (
        field: keyof TransmissionFormData,
        value: string | null,
    ) => {
        const updatedData = { ...formData, [field]: value }
        setFormData(updatedData)
        if (onDataChange) {
            onDataChange(updatedData)
        }
    }
    useEffect(() => {
        setFormData({ type: null })
    }, [reviewShow])

    const calculateRateText = (optionValue: string) => {
        if (optionValue === reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.componentType) {
            return `${(reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.rate * 100).toFixed(0)}%`
        }
        return null
    }

    const optionTemplate = (option: any) => {
        const rateText = calculateRateText(option.value)
        return (
            <div className="flex items-center">
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{option.label}</span>
            </div>
        )
    }

    const valueTemplate = (option: any) => {
        if (!option) return 'Select'
        const rateText = calculateRateText(option.value)
        return (
            <div className="flex items-center">
                <img
                    className="mr-1"
                    src={'/img/gemini-icon-2.png'}
                    style={{ width: '24px', height: '24px' }}
                    alt="star"
                />
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{option.label}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                    Transmission Tower Detected
                    {status}
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="type"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Type
                    </label>
                    <Dropdown
                        id="type"
                        options={TransmissionOptions}
                        value={formData.type || reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.componentType}
                        optionLabel="label"
                        optionValue="value"
                        onChange={(e) => handleChange('type', e.value)}
                        placeholder="Select"
                        className="w-full custom-dropdown"
                        itemTemplate={optionTemplate}
                        valueTemplate={valueTemplate}
                    />
                </div>
            </div>
        </div>
    )
}

const TransformerDetectionForm: React.FC<ServiceFormProps> = ({
    onDataChange,
    status
}) => {
    const [formData, setFormData] = useState<TransformerFormData>({
        numberOfTransformers: null,
        condition: null,
    })

    const handleChange = (
        field: keyof TransformerFormData,
        value: string | null,
    ) => {
        const updatedData = { ...formData, [field]: value }
        setFormData(updatedData)
        if (onDataChange) {
            onDataChange(updatedData)
        }
    }

    return (
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                    Transformer Detected
                    {status}
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="numberOfTransformers"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Number of Transformers
                    </label>
                    <Dropdown
                        id="numberOfTransformers"
                        options={TransformerNumberOptions}
                        optionLabel="label"
                        value={formData.numberOfTransformers}
                        onChange={(e) =>
                            handleChange('numberOfTransformers', e.value)
                        }
                        placeholder="Select"
                        className="w-full custom-dropdown"
                    />
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="condition"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Condition
                    </label>
                    <Dropdown
                        id="condition"
                        options={TransformerQualityOptions}
                        optionLabel="label"
                        value={formData.condition}
                        onChange={(e) => handleChange('condition', e.value)}
                        placeholder="Select"
                        className="w-full custom-dropdown"
                    />
                </div>
            </div>
        </div>
    )
}

const InsulatorDetectionForm: React.FC<ServiceFormProps> = ({
    onDataChange,
    status
}) => {
    const [formData, setFormData] = useState<InsulatorFormData>({
        type: null,
        material: null,
    })

    const handleChange = (
        field: keyof InsulatorFormData,
        value: string | null,
    ) => {
        const updatedData = { ...formData, [field]: value }
        setFormData(updatedData)
        if (onDataChange) {
            onDataChange(updatedData)
        }
    }

    return (
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                    Insulator Detected
                    {status}
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="type"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Type
                    </label>
                    <Dropdown
                        id="type"
                        options={InsulatorTypeOptions}
                        optionLabel="label"
                        value={formData.type}
                        onChange={(e) => handleChange('type', e.value)}
                        placeholder="Select"
                        className="w-full custom-dropdown"
                    />
                </div>
                <div className="flex flex-col mt-5">
                    <label
                        htmlFor="material"
                        className="text-sm font-medium text-graniteGray mb-2"
                    >
                        Materials
                    </label>
                    <Dropdown
                        id="material"
                        options={InsulatorMaterialOptions}
                        optionLabel="label"
                        value={formData.material}
                        onChange={(e) => handleChange('material', e.value)}
                        placeholder="Select"
                        className="w-full custom-dropdown"
                    />
                </div>
            </div>
        </div>
    )
}

const ReviewTab = () => {
    const token = useSelector((state: RootState) => state.global?.token)
    const tenantId = useSelector((state: RootState) => state.global?.tenantId)

    const [formData, setFormData] = useState<Record<string, any>>({})
    const [isReject, setIsReject] = useState(false)
    const { selectedTable, reviewType, reviewShow, reviewData } = useSelector(
        (state: RootState) => state.review,
    )

    const handleFormDataChange = (data: any) => {
        setFormData(data)
    }

    function determineStatus(): JSX.Element {
        let status: 'Pending Review' | 'In Progress' | 'Complete' =
            'Pending Review'

        if (selectedTable?.reviewId) {
            status = 'In Progress'
        }
        if (['Approved', 'Rejected'].includes(reviewData?.assetDetail?.status || '')) {
            status = 'Complete'
        }

        return <StatusBadge status={status} size="sm" />
    }

    const COMPONENTS: Record<string, React.ReactNode> = {
        insulatorDetection: (
            <InsulatorDetectionForm
                onDataChange={handleFormDataChange}
                status={determineStatus()}
            />
        ),
        transformerDetection: (
            <TransformerDetectionForm
                onDataChange={handleFormDataChange}
                status={determineStatus()}
            />
        ),
        wireDetection: (
            <WireDetectionForm
                onDataChange={handleFormDataChange}
                status={determineStatus()}
            />
        ),
        structureClassification: (
            <TransmissionDetectionForm
                onDataChange={handleFormDataChange}
                status={determineStatus()}
            />
        ),
        serviceDrops: (
            <ServiceDropDetectionForm
                onDataChange={handleFormDataChange}
                status={determineStatus()}
            />
        ),
    }

    const isFormDataActive = Object.values(formData).some(
        (value) => value !== '',
    )

    /**
     * Updates the defect's status and sends the updated data to the server.
     *
     * @param {DashboardDefectDto} newValueMap - The updated defect data.
     * @param {string} status - The new status to be set (e.g., 'Approved', 'Rejected').
     * @param rejectList
     */
    async function handleSubmit() {
        const param = {
            url: `/micro-fe/evaluate-defect`,
            method: 'put',
            data: {
                token,
                tenantId,
                id: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.id,
                status: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.status,
                assetId: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.assetId,
                type: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.type,
                componentType: formData.type || reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.componentType,
                priority: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.priority,
                severity: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.severity,
                feedbackList: [],
                others: formData,
            },
        }
        BaseService(param).then((response) => {
            window.location.reload();
        })
    }

    useEffect(() => {
        setFormData({})
    }, [reviewShow])

    const handleReject = () => {
        setIsReject(true)
    }

    /**
     * Transforms feedback data into an array of objects,
     * where each object contains a reason type and reason message.
     *
     * @param {Object} data - An object where keys are reason types and values are arrays of reasons.
     * @returns {Array<Object>} - An array of objects, each with `reasonType` and `reason` properties.
     */
    function transformFeedbackReject(data) {
        const transformedFeedback = []

        Object.entries(data).forEach(([reasonType, reasons]) => {
            reasons.forEach((reason) => {
                transformedFeedback.push({
                    reasonType: reasonType,
                    reason: reason,
                })
            })
        })

        return transformedFeedback
    }

    const sendFeedbackChange = async (feedbackList) => {
        try {
            await BaseService({
                url: '/ml-model/create-change-feedback/',
                method: 'post',
                data: {
                    feedback: {
                        assetId: selectedTable?.assetId,
                        defectId: selectedTable?.defectId,
                    },
                    list: transformFeedbackReject(feedbackList),
                },
            }).then(() => {
                window.location.reload()
            })
        } finally {
            setIsReject(null)
        }
    }

    return (
        <div className="flex flex-col rounded-lg bg-white border-l border-chineseWhite w-full mt-4 h-full">
            <div className="flex flex-col overflow-x-auto pt-4 pl-2 h-[calc(100vh-230px)] w-full">
                <div className="flex flex-col">
                    <AIBanner />
                </div>
                {reviewType && (
                    <div key={reviewType}>{COMPONENTS[reviewType] || null}</div>
                )}
            </div>
            <div className="flex flex-row ml-auto w-full px-2">
                <ActionButtons
                    isActive={false}
                    handleSubmit={handleSubmit}
                    handleReject={handleReject}
                />
                <RejectDialog
                    open={isReject}
                    setOpen={setIsReject}
                    onSend={(feedbackList) => sendFeedbackChange(feedbackList)}
                />
            </div>
        </div>
    )
}

export default ReviewTab
