// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from "react";
import ReviewMap from "../review-2/ReviewMap";
import ReviewView from "@/modules/review-2/review/ReviewView"
import {useDispatch, useSelector} from 'react-redux';
import {getReviewData, setInitialLoad, getUsers} from "@/store";
import SidePanel from "@/components/ui/SidePanel";

export type ReviewAsset = {
    id: number
    tenantId: number
    code: string
    type: ReviewAssetType
    geometry: string
    address: string
}
export enum ReviewAssetType {
    CIRCUIT = 'Circuit',
    POLE = 'Pole',
}

export type ReviewInspection = {
    id: number
    tenantId: number
    assetId: number
    inspectionTime: Date
    inspectionNumber: number
    createdAt: Date
    updatedAt: Date
    images: ReviewInspectionImage[]
}

export type ReviewInspectionImage = {
    id: number
    tenantId: number
    assetId: number
    inspectionId: number
    imageTime: Date
    blobName: string
    contentType: string
    createdAt: Date
    createdBy: number
    updatedAt: Date
    updatedBy: number
}

export default function ModuleReview() {
    const dispatch = useDispatch();
    const  {reviewData, reviewShow} = useSelector((state: RootState) => state.review);

    useEffect(() => {
        dispatch(getReviewData({ assetId: null }));
        dispatch(setInitialLoad(true));
        dispatch(getUsers());
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <div className="flex w-full h-[50px] p-3 justify-center bg-gray-100 custom border-b border-customBorderColor">
                <div className="flex flex-row w-[85%] justify-between">
                    <div className="w-[150px]"><img src="/logo/skyscope.png" alt="logo" /> </div>
                    <div className="flex flex-row justify-end gap-2">
                        <p className="text-base font-bold">{reviewData?.user?.displayName || ''}</p>
                        <SidePanel />
                    </div>
                </div>
            </div>
            <ReviewMap  />
            {reviewShow && <ReviewView /> }
        </div>
    );
}
