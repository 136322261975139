import React from 'react';
import { MdOutlineLightbulb } from 'react-icons/md';

const AIBanner = () => (
    <div className="flex flex-row text-arsenic py-2 pl-4 pr-2 w-full font-medium gap-2 bg-aliceBlue items-center">
        <div
            className="flex justify-center min-w-8 max-w-8 min-h-8 max-h-8 rounded-full bg-brightGray roundend-xl items-center content-center		  "
        >
            <img
                src={'/img/gemini-icon-2.png'}
                style={{ width: '24px', height: '24px' }}
                alt="star"
            />
        </div>
        ThreeV “AI Insight” Detected
    </div>
);

export default AIBanner;
