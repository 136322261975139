// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Image } from 'primereact/image'
import Button from '@/components/ui/Button'
import { HiZoomIn, HiZoomOut } from 'react-icons/hi'
import {
    MdOutlineImageSearch,
    MdOutlineRotate90DegreesCw,
} from 'react-icons/md'
import throttle from 'lodash/throttle'

/**
 * Component to display and manage defect images on the dashboard.
 * Handles loading images, applying zoom and rotation, and drawing bounding boxes for predictions.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.inspection - The inspection data object containing image and label information.
 * @param {number} props.activeImageIndex - The index of the currently active image in the inspection data.
 * @param {Object} props.imageState - The state of the image display, including zoom level and rotation angle.
 * @param {number} props.imageState.zoom - The zoom level for the image (1 is the default zoom level).
 * @param {number} props.imageState.rotation - The rotation angle for the image in degrees.
 * @param {Function} props.setImageState - The function to update the image display state.
 *
 * @returns {JSX.Element} A React component that displays the defect image with optional predictions drawn on a canvas.
 */
export default function ImageView({
    src,
    toolbar = false,
    className,
    imageClassName,
    preview,
    fullWidth = false,
    viewRef,
    reset = false,
}) {
    const [isMagnifyActive, setIsMagnifyActive] = useState<boolean>(null)
    const [cursorPosition] = useState({ x: 0, y: 0 })
    const [imageState, setImageState] = useState({
        zoom: 1, // 1 is the default zoom level
        rotation: 0, // Rotation in degrees
    })

    const imageRef = useRef(null)
    const styleRef = useRef(null)
    const lensSize = 150 // Size of the magnifying lens
    const fixedZoomLevel = 10 // Magnification level for the lens

    // Mouse dragging state
    const isDragging = useRef(false)
    const startX = useRef(0)
    const startY = useRef(0)
    const translateX = useRef(0)
    const translateY = useRef(0)

    /**
     * Effect hook that runs when the inspection data or active image index changes.
     * Resets the image and canvas, sets the loading state, and retrieves the new image.
     */
    useEffect(() => {
        setImageState({
            zoom: 1, // 1 is the default zoom level
            rotation: 0, // Rotation in degrees
        })
    }, [])

    useEffect(() => {
        if (reset){
            setImageState({
                zoom: 1, // 1 is the default zoom level
                rotation: 0, // Rotation in degrees
            })
        }
    }, [reset]);

    /**
     * Increases the zoom level of the image.
     * The zoom level is increased by 0.1 each time the function is called.
     */
    const handleZoomIn = () => {
        setImageState((prevState) => ({
            ...prevState,
            zoom: prevState.zoom + 0.1, // Increase zoom level
        }))
    }

    /**
     * Decreases the zoom level of the image.
     * The zoom level is decreased by 0.1 each time the function is called, but it will not go below 0.1.
     */
    const handleZoomOut = () => {
        setImageState((prevState) => ({
            ...prevState,
            zoom: Math.max(prevState.zoom - 0.1, 0.1), // Decrease zoom level, but not below 0.1
        }))
    }

    /**
     * Rotates the image by 90 degrees clockwise.
     * Each call to this function increases the rotation angle by 90 degrees.
     */
    const handleRotate = () => {
        setImageState((prevState) => ({
            ...prevState,
            rotation: prevState.rotation + 90, // Rotate image by 90 degrees
        }))
    }

    /**
     * Calculates the transform string for the image element based on the current zoom, rotation, and translation values.
     *
     * @returns {string} The CSS transform string for the image element.
     */
    const calculateTransform = () => {
        const { zoom, rotation } = imageState
        return `
        scale(${zoom || 1})
        rotate(${rotation || 0}deg)
        translate(${translateX.current || 0}px, ${translateY.current || 0}px)
    `
    }

    /**
     * Updates the image element's style based on the current zoom, rotation, and translation values.
     */
    const updateImageStyle = useCallback(() => {
        if (styleRef.current) {
            const imgElement = styleRef.current.getImage()
            if (imgElement) {
                imgElement.style.transform = calculateTransform()
            }
        }
    }, [imageState, styleRef])

    /**
     * Handles the mousedown event to start dragging the image.
     * Sets the initial cursor position and adds the mousemove event listener.
     *
     * @param {MouseEvent} event - The mouse down event.
     */
    const onMouseDown = (event) => {
        event.preventDefault() // Prevent text selection
        if (imageState.zoom > 1) {
            // Check if zoom is greater than 1
            isDragging.current = true
            startX.current = event.clientX
            startY.current = event.clientY

            // Start tracking mouse movements
            window.addEventListener('mousemove', onMouseMove)
        }
    }

    /**
     * Handles the mouseup event to stop dragging the image.
     * Removes the mousemove event listener.
     */
    const onMouseUp = () => {
        isDragging.current = false

        // remove mouse leave.
        window.removeEventListener('mousemove', onMouseMove)
    }

    /**
     * Handles mouse movements while dragging the image.
     * Updates the image translation based on the movement delta and current rotation.
     *
     * @param {MouseEvent} event - The mouse move event.
     */
    const onMouseMove = useCallback(
        throttle((event) => {
            if (isDragging.current && imageState.zoom > 1) {
                const deltaX = event.clientX - startX.current
                const deltaY = event.clientY - startY.current

                // Calculate angle in radians based on rotation
                const angleInRadians =
                    (imageState.rotation % 360) * (Math.PI / 180)

                // Adjust deltas for rotation
                const adjustedDeltaX =
                    deltaX * Math.cos(angleInRadians) +
                    deltaY * Math.sin(angleInRadians)
                const adjustedDeltaY =
                    deltaY * Math.cos(angleInRadians) -
                    deltaX * Math.sin(angleInRadians)

                // Apply adjusted deltas to the translate values
                translateX.current += adjustedDeltaX
                translateY.current += adjustedDeltaY

                startX.current = event.clientX
                startY.current = event.clientY

                // Call the shared style update
                updateImageStyle()
            }
        }, 50),
        [updateImageStyle, imageState.zoom, imageState.rotation],
    )

    /**
     * Effect hook to update the image style whenever the imageState changes.
     */
    useEffect(() => {
        updateImageStyle()
    }, [updateImageStyle])

    /**
     * Effect hook to add and clean up event listeners for mouse events.
     */
    useEffect(() => {
        return () => {
            window.removeEventListener('mousedown', onMouseDown)
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [])

    /**
     * Merges multiple refs into a single ref callback function.
     * This function allows you to combine multiple ref objects or functions into a single ref,
     * which will be called with the element whenever it mounts or unmounts.
     *
     * @param {...(React.RefCallback<T> | React.RefObject<T>)} refs - The refs to be merged.
     * @returns {React.RefCallback<T>} A ref callback function that updates all provided refs.
     *
     * @template T - The type of the element that the refs are referring to.
     */
    const mergeRefs = (...refs) => {
        return (element) => {
            refs.forEach((ref) => {
                if (typeof ref === 'function') {
                    ref(element)
                } else if (ref) {
                    ref.current = element
                }
            })
        }
    }

    return (
        <>
            <div
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                    transition: 'transform 0.1s',
                    ...(fullWidth && { width: '100%' }),
                }}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
            >
                {/* Buttons for zooming in, zooming out, and rotating the image */}
                {toolbar && (
                    <div className="absolute top-0 right-5 rotate-image-header">
                        <Button
                            variant="filled"
                            size="xs"
                            icon={<HiZoomIn className="text-xl" />}
                            onClick={handleZoomIn}
                        />
                        <Button
                            variant="filled"
                            size="xs"
                            icon={<HiZoomOut className="text-xl" />}
                            onClick={handleZoomOut}
                        />

                        <Button
                            variant="filled"
                            size="xs"
                            icon={
                                <MdOutlineRotate90DegreesCw className="text-xl" />
                            }
                            onClick={handleRotate}
                        />
                        {isMagnifyActive !== null && (
                            <Button
                                variant="filled"
                                size="xs"
                                active={isMagnifyActive}
                                icon={
                                    <MdOutlineImageSearch className="text-xl" />
                                }
                                onClick={() =>
                                    setIsMagnifyActive(!isMagnifyActive)
                                }
                            />
                        )}
                    </div>
                )}
                <Image
                    ref={mergeRefs(imageRef, styleRef, viewRef)}
                    imageClassName={imageClassName}
                    className={className}
                    src={src}
                    alt="Preview"
                    preview={preview}
                    style={{
                        width: '100%',
                        height: '100%',
                        transformOrigin: 'center center',
                        transition: 'transform 0.1s',
                        cursor: isDragging.current ? 'move' : 'grab', // Updated cursor
                    }}
                />
                {isMagnifyActive !== null && (
                    <div
                        style={{
                            position: 'absolute',
                            top: `calc(${cursorPosition.y}% - ${
                                lensSize / 2
                            }px)`,
                            left: `calc(${cursorPosition.x}% - ${
                                lensSize / 2
                            }px)`,
                            width: `${lensSize}px`,
                            height: `${lensSize}px`,
                            borderRadius: '50%',
                            backgroundImage: `${src}`,
                            backgroundSize: `${100 * fixedZoomLevel}px ${
                                100 * fixedZoomLevel
                            }px`,
                            backgroundPosition: `-${
                                cursorPosition.x * fixedZoomLevel
                            }px -${cursorPosition.y * fixedZoomLevel}px`,
                            pointerEvents: 'none',
                            border: '2px solid #fff',
                            boxShadow: '0 0 8px rgba(0,0,0,0.5)',
                            zIndex: 2,
                        }}
                    />
                )}
            </div>
        </>
    )
}
