// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'
import Button from "@/components/ui/Button";
import {Dialog} from "primereact/dialog";
import {MdCheck} from "react-icons/md";
import {Checkbox} from "primereact/checkbox";


type RejectDialogProps = {
    open: boolean,
    setOpen: (val: boolean) => boolean,
    onSend: (val: any) => void,
};

const RejectOptions = [
    {
        category: 'Not a Defect',
        items: [
            'The identified issue is not an actual defect.',
            'Routine or expected wear and tear, not requiring action.',
        ],
    },
    {
        category: 'Inaccurate Model Prediction',
        items: [
            'Model misidentified the type of defect.',
            'False positive: defect identified, but it doesn’t exist.',
            'Prediction didn’t align with real-world conditions.',
        ],
    },
    {
        category: 'Missing or Insufficient Data',
        items: [
            'Insufficient image quality or resolution.',
            'Missing critical information to confirm the defect.',
            'Key components not visible or obscured in the image.',
        ],
    },
    {
        category: 'Data Annotation or Labeling Issues',
        items: [
            'Error in the training data or mislabeled images leading to incorrect output.',
            'Model followed outdated or inconsistent labeling standards.',
        ],
    },
    {
        category: 'Repetitive or Duplicate Detection',
        items: [
            'Defect already logged or captured in a previous work item.',
            'Redundant identification of the same defect on the same asset.',
        ],
    },
    {
        category: 'Operational or Safety Non-Relevance',
        items: [
            'Defect not severe enough to impact operational safety or function.',
            'Item flagged doesn’t meet the urgency level for work order submission.',
        ],
    },
    {
        category: 'Incorrect Classification',
        items: [
            'Incorrect classification of asset or defect type.',
            'Model misclassified a minor issue as a critical defect.',
        ],
    },
    {
        category: 'Other (Specify)',
        items: [
            'Option to provide specific, custom feedback relevant to unique cases.',
        ],
    },
]

/**
 * DashboardFeedbackDialog component for handling reject category and item selection feedback.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {function} props.setOpen - Function to toggle the dialog's open state.
 * @param {function} props.onSend - Function to handle the submission of reject feedback data.
 *
 * @returns {React.Element} The rendered component.
 */
const RejectDialog: React.FC<RejectDialogPropsfvalueMap> = ({ open, setOpen, onSend }) => {
    const [selectedRejects, setSelectedRejects] = useState({})

    /**
     * Toggles selection for a given reject category.
     * If the category is already selected, it will be removed; otherwise, it will be added with an empty array.
     *
     * @param {string} category - The reject category to select or deselect.
     */
    const handleRejectSelect = (category) => {
        setSelectedRejects((prev) => {
            if (prev[category]) {
                const updatedCategories = { ...prev };
                delete updatedCategories[category];
                return updatedCategories;
            }
            return {
                ...prev,
                [category]: [],
            };
        });
    };

    /**
     * Toggles selection of an item within a specific reject category.
     * If the item is already selected in the category, it will be removed; otherwise, it will be added.
     *
     * @param {string} category - The category in which the item is selected.
     * @param {string} item - The item to toggle within the selected category.
     */
    const handleItemClickRejects = (category, item) => {
        setSelectedRejects((prev) => {
            const currentItems = prev[category] || []
            const isSelected = currentItems.includes(item)
            return {
                ...prev,
                [category]: isSelected
                    ? currentItems.filter((i) => i !== item)
                    : [...currentItems, item],
            }
        })
    }

    /**
     * Checks if the reject send button should be disabled.
     * It is disabled if there are no selected items across all reject categories.
     *
     * @type {boolean}
     */
    const isSendRejectDisabled = !Object.values(selectedRejects).some(
        (items) => items.length > 0,
    )

    return (
        <Dialog
            header="Reason for rejection"
            visible={open}
            style={{ width: '700px' }}
            modal={false}
            draggable={false}
            headerClassName="p-0 pl-6 pt-6"
            closable={false}
            onHide={() => setOpen(false)}
        >
            <div className="font-roboto mt-2 mb-5">
                <p className="text-base text-balticSea font-normal">Help Us Improve Accuracy</p>
                <p className="text-sm text-smoky font-normal">We noticed that you’ve made multiple adjustments to the AI model’s predictions.Could you please let us know what happened? </p>
            </div>
            <div className="flex flex-row flex-wrap mb-1 gap-2">
                <p className="flex w-full font-medium text-sm text-shipGray">Select the most relevant reject below</p>
                {RejectOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`flex flex-row items-center border py-1 pl-3 pr-2 border-gainsboro rounded cursor-pointer text-shipGray text-xs ${
                            selectedRejects[option.category]
                                ? 'bg-aliceBlue text-trueBlue border-none !pl-2'
                                : ''
                        }`}
                        onClick={() => handleRejectSelect(option.category)}
                    >
                        { selectedRejects[option.category] && <MdCheck className="text-trueBlue mr-2 text-xs" />}
                        {option.category}
                    </div>
                ))}

                <div className="flex flex-col w-full mb-1 gap-2 mt-6 h-[300px] overflow-y-auto font-roboto">
                    {Object.keys(selectedRejects).map((category) => (
                        <div
                            key={category}
                            className="flex flex-col w-full gap-2 mt-1 text-sm mb-6"
                        >
                            <h3 className="text-xs text-shipGray font-medium">{category}</h3>
                            {RejectOptions.find(
                                (option) => option.category === category,
                            )?.items.map((item, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-row py-4 px-2  cursor-pointer w-full text-sm font-normal border-graySuit border-b text-balticSea`}
                                    onClick={() =>
                                        handleItemClickRejects(category, item)
                                    }
                                >
                                    <Checkbox className="mr-4 custom-checkbox" checked={selectedRejects[category].includes(item)}></Checkbox>
                                    {item}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-end">
                <Button
                    shape="round"
                    variant="custom"
                    size="sm"
                    className="w-[100px] flex items-end justify-center"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    shape="round"
                    variant="custom"
                    size="sm"
                    className="w-[100px] flex items-end justify-center"
                    color="trueBlue"
                    textColor="text-white"
                    disabled={isSendRejectDisabled}
                    onClick={() => onSend(selectedRejects)}
                >
                    Reject
                </Button>
            </div>
        </Dialog>
    )
}

export default RejectDialog;
