// eslint-disable-next-line import/export
import store from './storeSetup'

// Re-export everything from slices
export * from './storeSetup'
export * from './slices/dashboard/dashboardSlice'
export * from './slices/dashboard/commentSlice'
export * from './slices/dashboard/reviewSlice'
export * from './slices/dashboard/globalSlice'
export * from './slices/dashboard/notificationSlice'
export * from './rootReducer'
export * from './hook'

// Default export
export default store
