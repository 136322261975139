// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useMemo, useRef, useState } from 'react'
import { Button, ImageView } from '@/components/ui'
import { Skeleton } from 'primereact/skeleton'
import {
    getReviewImages,
    getTransmissionStructure,
    RootState,
    setReviewMainImage,
    useAppSelector
} from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import ReviewThumbs from '@/modules/review-2/review/ReviewThumbs'
import { Image } from 'primereact/image'
import useStorageAccess from '@/hooks/useStorageAccess'
import { HiOutlineThumbDown, HiOutlineThumbUp } from 'react-icons/hi'
import BaseService from '@/services/BaseService'
import type { AxiosResponse } from 'axios'
import { IoClose } from 'react-icons/io5'
import {getPreviewBlobName} from "@/utils";
import { Image as PrimeReactImage } from 'primereact/image';
import {
    setReviewMainImageLoading,
} from '@/store'

export function FeedbackButtons() {
    const token = useAppSelector((state) => state.global.token);
    const tenantId = useAppSelector((state) => state.global.tenantId);

    const [showPopup, setShowPopup] = useState(false)
    const [thumbsStatus, setThumbsStatus] = useState([])

    const { selectedTable, selectedImage, reviewData } = useSelector(
        (state: RootState) => state.review,
    )

    const inspection = reviewData?.assetDetail?.inspections?.find(
        (val) => val.assetId == selectedTable?.id,
    )

    useEffect(() => {
        if (reviewData?.assetDetail?.asset?.id) {
            getThumbsDownReasons()
        }
    }, [reviewData])

    // Memoized feedback status of the current image
    const currentImageHasFeedback = useMemo(() => {
        if (!thumbsStatus) return null
        return (
            thumbsStatus?.filter(
                (item) =>
                    item?.imageId === inspection?.images?.[selectedImage]?.id,
            ) || null
        )
    }, [thumbsStatus, inspection, selectedImage])

    /**
     * Creates the feedback data object with details about the image and the feedback provided.
     * Optionally includes a reason for negative feedback.
     *
     * @param {boolean} feedback - The feedback value (true for positive, false for negative)
     * @param {string} [reason=''] - The reason for negative feedback (optional)
     * @returns {object} The feedback data object
     */
    const createFeedbackData = (feedback, reason = '') => ({
        assetId: inspection?.images?.[selectedImage]?.assetId,
        imageId: inspection?.images?.[selectedImage]?.id,
        contentType: inspection?.images?.[selectedImage]?.contentType,
        blobName: inspection?.images?.[selectedImage]?.blobName,
        feedback,
        reason,
        token,
        tenantId
    })

    /**
     * Retrieves the list of thumbs-down feedback reasons for the current asset.
     * Updates the state with the retrieved feedback data.
     */
    async function getThumbsDownReasons() {
        try {
            const param = {
                url: `/micro-fe/feedbacks?assetId=${reviewData?.assetDetail?.asset?.id}&token=${token}&tenantId=${tenantId}`,
                method: 'get',
                params: {},
            }
            const response: AxiosResponse<Response> = await BaseService(param)
            setThumbsStatus(response.data?.feedbacks)
        } catch (error) {
            console.error('Error fetching thumbs down reasons:', error)
            setThumbsStatus([])
        }
    }

    /**
     * Sends the feedback data to the server.
     * Sets the loading state during the request and marks all images as reviewed after submission.
     *
     * @param {object} data - The feedback data object to be sent
     */
    const sendFeedback = async (data) => {
        try {
            await BaseService({
                url: '/micro-fe/create-feedback',
                method: 'post',
                data,
            })
        } finally {
            getThumbsDownReasons()
        }
    }

    /**
     * Deletes feedback with the specified ID and refreshes the list of thumbs-down reasons.
     *
     * @param {number|string} id - The ID of the feedback to delete.
     */
    const deleteFeedback = async (id) => {
        try {
            await BaseService({
                url: `/micro-fe/delete-feedback/${id}?tenantId=${tenantId}&token=${token}`,
                method: 'delete',
            })
        } catch (error) {
            console.error(error)
        } finally {
            getThumbsDownReasons()
        }
    }

    /**
     * Handles the thumb action by sending or deleting feedback based on the current state.
     * Specifically, this function handles positive feedback and toggles a popup for negative feedback.
     *
     * @param {boolean} thumbFeedback - The feedback value:
     *   - `true` for positive feedback (thumbs up)
     *   - `false` for negative feedback (thumbs down)
     */
    const handleThumbAction = (thumbFeedback) => {
        // Only handle positive feedback actions
        if (thumbFeedback) {
            // Create feedback data for positive feedback
            const feedbackData = createFeedbackData(thumbFeedback)

            // Check if there's existing positive feedback
            const existingFeedback = currentImageHasFeedback?.find(
                (feed) => feed.feedback === true,
            )

            if (existingFeedback) {
                // If positive feedback already exists, delete it
                deleteFeedback(existingFeedback.id)
            } else {
                // Otherwise, send new positive feedback
                sendFeedback(feedbackData)

                const existingFeedbacks = currentImageHasFeedback?.filter(
                    (feed) => feed.feedback === false,
                )
                existingFeedbacks?.forEach((feedback) => {
                    deleteFeedback(feedback.id)
                })
            }
        } else {
            setShowPopup((prevState) => !prevState)
        }
    }

    /**
     * Handles the selection of a reason for negative feedback.
     * If there is existing positive feedback for the same reason, it is deleted.
     * It then handles the negative feedback by either deleting existing negative feedback
     * or sending new negative feedback based on the current state.
     * Finally, it closes the feedback popup.
     *
     * @param {string} reason - The reason for the negative feedback.
     */
    const handleReasonSelect = (reason) => {
        const data = createFeedbackData(false, reason)

        // Check for positive feedback for the same reason
        const positiveFeedbackExists = currentImageHasFeedback?.find(
            (feed) => feed.feedback === true,
        )
        if (positiveFeedbackExists) {
            deleteFeedback(positiveFeedbackExists.id)
        }

        const existingFeedback = currentImageHasFeedback?.find(
            (feed) => feed.reason === reason,
        )
        if (existingFeedback) {
            deleteFeedback(existingFeedback?.id)
        } else {
            sendFeedback(data)
        }
    }

    /**
     * Closes the reason selection popup.
     */
    const handlePopupClose = () => {
        setShowPopup(false)
    }

    const isPositiveFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === true,
    )
    const isNegativeFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === false,
    )

    const feedbackReasons = ['Missed Asset', 'Asset wrongly identified']

    return (
        <div className="flex space-x-2 z-50 absolute bottom-4 left-1/2 transform -translate-x-1/2">
            {/* Button for positive feedback (Thumbs Up) */}
            <Button
                variant="custom"
                size="xs"
                color={`${isPositiveFeedback ? 'trueBlue' : 'chineseWhite'}`}
                textColor={`${isPositiveFeedback ? 'text-white' : 'text-gray'}`}
                icon={<HiOutlineThumbUp className="text-xl" />}
                onClick={() => handleThumbAction(true)}
            />

            {/* Button for negative feedback (Thumbs Down) */}
            <Button
                variant="custom"
                size="xs"
                color={`${isNegativeFeedback ? 'trueBlue' : 'chineseWhite'}`}
                textColor={`${isNegativeFeedback ? 'text-white' : 'text-gray'}`}
                icon={<HiOutlineThumbDown className="text-xl" />}
                onClick={() => handleThumbAction(false)}
            />

            {/* Reasons popup for negative feedback */}
            {showPopup && (
                <div className="absolute -top-20 left-1/2 transform -translate-x-1/2 z-50">
                    <div className="relative -bottom-[25px] left-[15px] bg-white shadow-lg rounded-lg p-2 flex flex-col items-center">
                        <div className="absolute -bottom-[4px] left-1/5 transform -translate-x-1/2 w-0 h-0 border-x-4 border-x-transparent border-t-4 border-t-white"></div>
                        <div className="flex m-0 gap-1">
                            {feedbackReasons.map((reason) => {
                                const isActive = currentImageHasFeedback.some(
                                    (feedback) => feedback.reason === reason,
                                )
                                return (
                                    <Button
                                        key={reason}
                                        size="xs"
                                        variant="twoTone"
                                        textColor="text-arsenic"
                                        className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded transition-colors duration-200"
                                        active={isActive}
                                        onClick={() =>
                                            handleReasonSelect(reason)
                                        }
                                    >
                                        {reason}
                                    </Button>
                                )
                            })}

                            {/* Close popup button */}
                            <Button
                                size="xs"
                                variant="custom"
                                onClick={handlePopupClose}
                            >
                                <IoClose className="text-xl" />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const rleFromString = (encodedString) => {
    const characterCodes = encodedString
        .split('')
        .map((char) => char.charCodeAt(0))

    let codePointer = 0
    let ith = 0
    let count = 0
    let more = 0

    const counts = []

    while (characterCodes[codePointer]) {
        count = 0
        ith = 0
        more = 1

        while (more) {
            const char = characterCodes[codePointer] - 48
            count |= (char & 0x1f) << (5 * ith)
            more = char & 0x20
            codePointer++
            ith++
            if (!more && char & 0x10) count |= -1 << (5 * ith)
        }

        if (counts.length > 2) {
            count += counts[counts.length - 2]
        }
        counts.push(count)
    }

    return counts
}

function decodeCocoRLE([rows, cols], counts, flat = true) {
    let pixelPosition = 0,
        binaryMask

    if (flat) {
        binaryMask = Array(rows * cols).fill(0)
    } else {
        binaryMask = Array.from({ length: rows }, (_) => Array(cols).fill(0))
    }

    for (let i = 0, rleLength = counts.length; i < rleLength; i += 2) {
        const zeros = counts[i],
            ones = counts[i + 1] ?? 0

        pixelPosition += zeros

        while (ones > 0) {
            const rowIndex = pixelPosition % rows,
                colIndex = (pixelPosition - rowIndex) / rows

            if (flat) {
                const arrayIndex = rowIndex * cols + colIndex
                binaryMask[arrayIndex] = 1
            } else {
                binaryMask[rowIndex][colIndex] = 1
            }

            pixelPosition++
            ones--
        }
    }

    if (!flat) {
        console.log('Result matrix:')
        binaryMask.forEach((row, i) => console.log(row.join(' '), `- row ${i}`))
    }

    return binaryMask
}

const waitForImageLoad = (image: HTMLImageElement): Promise<void> => {
    return new Promise((resolve) => {
        if (image.complete) {
            resolve();
        } else {
            image.onload = () => resolve();
        }
    });
};

export default function ReviewImage() {
    const dispatch = useDispatch()
    const {
        reviewMainImage,
        reviewMainImageLoading,
        reviewData,
        selectedTable,
        selectedImage,
        selectedImageData,
        reviewType,
        maskImage,
        reviewShow
    } = useSelector((state: RootState) => state.review)

    const storageAccess = useStorageAccess()

    const imageRef = useRef(null)
    const commentRef = useRef(null)
    const canvasRef = useRef(null)

    const [canvasImage, setCanvasImage] = useState(null)
    const [isAILoading, setIsAILoading] = useState(false)
    const [mainImage, setMainImage] = useState(null)
    const [controller, setController] = useState<AbortController | null>(null);

    useEffect(() => {
        if (controller && !controller.signal.aborted) {
            controller.abort();
        }

        const newController = new AbortController();
        setController(newController);

        setMainImage(null);
        setCanvasImage(null);
        resetCanvas();

        dispatch(setReviewMainImageLoading(true));
        setIsAILoading(false);


        if (!reviewShow || !selectedImageData) {
            dispatch(setReviewMainImageLoading(false));
            return;
        }

        const previewBlobUrl = getBlobUrl(getPreviewBlobName(selectedImageData?.blobName));
        if (!previewBlobUrl) {
            dispatch(setReviewMainImageLoading(false));
            return;
        }

        console.log("previewBlobUrlpreviewBlobUrl", previewBlobUrl)

        const imageElement = new window.Image();
        imageElement.crossOrigin = 'anonymous';
        imageElement.src = previewBlobUrl;
        setMainImage(imageElement);
        dispatch(setReviewMainImageLoading(false));

        const handleImageProcessing = () => {
            if (!reviewData?.assetDetail?.inspections?.length || !selectedImageData) return;
            if (!storageAccess.token || !storageAccess.storageName) return; // Add this check

            const inspection = reviewData.assetDetail.inspections.find(
                (val) => val.assetId === selectedTable.inspectionId
            );

            if (reviewType === 'wireDetection') {
                return;
                setIsAILoading(true);
                const masks = inspection.defects
                    .filter((defect) => selectedTable.defectId === defect.id)
                    .map((defectMask) => defectMask.masks)
                    .filter((masks) => masks?.length);

                const imgPredictionList = inspection.defects.filter(
                    (defect) => selectedTable.defectId === defect.id
                );

                if (masks?.length > 0) {
                    dispatch(
                        getTransmissionStructure({
                            file: masks,
                            storageName,
                            token,
                        })
                    )
                        .then((data) => {
                            if (data?.payload && Array.isArray(data.payload)) {
                                const maskMap = data.payload.map((mask) => ({
                                    size: mask.size,
                                    counts: rleFromString(mask.counts),
                                }));

                                if (imgPredictionList?.[0]?.label?.img_prediction_list?.[0]) {
                                    setCanvasImage(
                                        drawFromBinaryMask(
                                            maskMap,
                                            imgPredictionList[0].label.img_prediction_list[0],
                                            imageElement
                                        )
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error('Transmission structure error:', error);
                        })
                        .finally(() => {
                            setIsAILoading(false);
                        });
                } else {
                    setIsAILoading(false);
                }
            } else {
                handleDraw(true, imageElement);
            }
        };

        const loadHighQualityImage = (highQualityBlobUrl: string, signal: AbortSignal) => {
            const qualityImageElement = new window.Image();
            qualityImageElement.crossOrigin = 'anonymous';
            qualityImageElement.src = highQualityBlobUrl;

            const onLoad = () => {
                if (signal.aborted) return;
                setMainImage(qualityImageElement);
                setCanvasImage(null);
                handleDraw(false, qualityImageElement);
            };

            const onError = () => {
                if (signal.aborted) return;
                console.error('High-quality image loading failed');
            };

            qualityImageElement.onload = onLoad;
            qualityImageElement.onerror = onError;

            signal.addEventListener('abort', () => {
                qualityImageElement.onload = null;
                qualityImageElement.onerror = null;
                qualityImageElement.src = '';
            });
        };

        imageElement.onload = () => {
            handleImageProcessing();
            console.log("previewBlobUrlpreviewBlobUrl00", selectedImageData?.blobName)
            // Here we generate the high-quality image URL
            const highQualityBlobUrl = getBlobUrl(selectedImageData?.blobName); // Ensure this is for the high-quality image
            if (!highQualityBlobUrl) return;

            if (reviewType !== 'wireDetection') {
                loadHighQualityImage(highQualityBlobUrl, newController.signal);
            }
        };

        imageElement.onerror = () => {
            dispatch(setReviewMainImageLoading(false));
            console.error('Image loading failed');
        };

        return () => {
            setMainImage(null);
            setCanvasImage(null);
            resetCanvas();
            setIsAILoading(false);
            dispatch(setReviewMainImageLoading(false));
            newController.abort();
        };
    }, [reviewData, selectedImage, selectedImageData, reviewShow, reviewType, storageAccess]);


    function drawFromBinaryMask(list, imgPredictionList, imageElement, preview = false) {
        // list and imgPredictionList controls
        if (!list || !Array.isArray(list) || list.length === 0 || !imgPredictionList) {
            console.error('Invalid data received:', { list, imgPredictionList });
            return null;
        }

        // first element size controls
        if (!list[0]?.size || !Array.isArray(list[0].size) || list[0].size.length < 2) {
            console.error('Invalid size data:', list[0]);
            return null;
        }

        const fillColor = [0, 255, 0, 200],
            height = list[0].size[0],
            width = list[0].size[1]

        if (!canvasRef.current || !imageElement) {
            console.error('Canvas or Image reference not found');
            return null;
        }

        canvasRef.current.height = height;
        canvasRef.current.width = width;

        // willReadFrequently özelliğini true olarak ayarla
        const canvasCtx = canvasRef.current.getContext('2d', {
            willReadFrequently: true,
            // WebGL context sayısını kontrol etmek için
            alpha: false,
            desynchronized: true
        });

        if (!canvasCtx) {
            console.error('Could not get canvas context');
            return null;
        }

        // Canvas'ı temizle
        canvasCtx.clearRect(0, 0, width, height);

        // Önceki context'i dispose et
        try {
            const gl = canvasRef.current.getContext('webgl');
            if (gl) {
                gl.getExtension('WEBGL_lose_context')?.loseContext();
            }
        } catch (e) {
            console.warn('WebGL context cleanup failed:', e);
        }

        canvasCtx.drawImage(imageElement, 0, 0, width, height);

        const multiplier = 1

        let index = 0
        for (const { size, counts } of list) {
            // bbox control
            if (!imgPredictionList?.b_box?.[index] ||
                !Array.isArray(imgPredictionList.b_box[index]) ||
                imgPredictionList.b_box[index].length < 2) {
                console.error('Invalid bbox data for index:', index);
                continue;
            }

            const maskFlattened = decodeCocoRLE(size, counts),
                maskLength = maskFlattened.length

            const bbox = imgPredictionList.b_box[index]

            const x1 = bbox[0][0] * multiplier
            const y1 = bbox[0][1] * multiplier
            const x2 = bbox[1][0] * multiplier
            const y2 = bbox[1][1] * multiplier

            canvasCtx.beginPath()
            canvasCtx.strokeStyle = 'red'
            canvasCtx.stroke()

            // conf_list and label_list control
            if (imgPredictionList?.conf_list?.[index] !== undefined &&
                imgPredictionList?.label_list?.[index]) {
                const conf = (imgPredictionList.conf_list[index] * 100).toFixed(0)
                const confText = `${imgPredictionList.label_list[index]} ${conf}%`
                canvasCtx.font = `${55}px roboto`
                canvasCtx.fillStyle = 'green'
                const fillWidth = canvasCtx.measureText(confText).width + 20
                const fillHeight = preview ? 15 : 65
                canvasCtx.fillRect(
                    x1 - (preview ? 2 : 10),
                    y1 - fillHeight,
                    fillWidth,
                    fillHeight,
                )
                canvasCtx.fillStyle = 'white'
                canvasCtx.fillText(confText, x1 - 2, y1 - 3)
            }

            index++
        }

        const imgData = canvasCtx.getImageData(0, 0, width, height)
        const pixelData = imgData.data

        for (const { size, counts } of list) {
            const maskFlattened = decodeCocoRLE(size, counts),
                maskLength = maskFlattened.length

            for (let i = 0; i < maskLength; i++) {
                if (maskFlattened[i] === 1) {
                    const pixelPosition = i * 4

                    pixelData[pixelPosition] = fillColor[0]
                    pixelData[pixelPosition + 1] = fillColor[1]
                    pixelData[pixelPosition + 2] = fillColor[2]
                    pixelData[pixelPosition + 3] = fillColor[3]
                }
            }
        }

        canvasCtx.putImageData(imgData, 0, 0)
        return canvasRef.current.toDataURL()
    }

    /**
     * Resets the canvas by clearing its context and setting its dimensions to zero.
     */
    function resetCanvas() {
        if (canvasRef.current) {
            setCanvasImage(null);

            // 2D context'i temizle
            const ctx = canvasRef.current.getContext('2d', {
                willReadFrequently: true
            });
            if (ctx) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            }

            // WebGL context'i dispose et
            try {
                const gl = canvasRef.current.getContext('webgl');
                if (gl) {
                    gl.getExtension('WEBGL_lose_context')?.loseContext();
                }
            } catch (e) {
                console.warn('WebGL context cleanup failed:', e);
            }

            canvasRef.current.width = 0;
            canvasRef.current.height = 0;
        }
    }

    /**
     * Draws bounding boxes and confidence scores on the canvas based on the image predictions.
     * Scales and adjusts the drawing based on whether the image is a preview or full-resolution.
     *
     * @param {boolean} preview - Whether the image being drawn is a preview.
     */
    async function handleDraw(preview: boolean, imageElement: HTMLImageElement) {
        try {
            let imgPrediction = null;
            const imgPredictionList = selectedImageData.label?.img_prediction_list;
            if (imgPredictionList?.length) imgPrediction = imgPredictionList[0];

            if (!imageElement || !imgPrediction || !imgPrediction.b_box.length) {
                setCanvasImage(null);
                return;
            }

            await waitForImageLoad(imageElement);

            const width = imageElement.naturalWidth;
            const height = imageElement.naturalHeight;
            const naturalWidth = preview ? selectedImageData.width : width;
            const multiplier = width / naturalWidth;

            canvasRef.current.width = width;
            canvasRef.current.height = height;

            const ctx = canvasRef.current.getContext('2d', {
                willReadFrequently: true,
                alpha: false,
                desynchronized: true
            });

            if (!ctx) {
                console.error('Could not get canvas context');
                return;
            }

            ctx.clearRect(0, 0, width, height);

            // Önceki context'i dispose et
            try {
                const gl = canvasRef.current.getContext('webgl');
                if (gl) {
                    gl.getExtension('WEBGL_lose_context')?.loseContext();
                }
            } catch (e) {
                console.warn('WebGL context cleanup failed:', e);
            }

            // Canvas güvenlik ayarları
            canvasRef.current.crossOrigin = "anonymous";

            try {
                ctx.drawImage(imageElement, 0, 0, width, height);
            } catch (error) {
                console.error('Error drawing image to canvas:', error);
                return;
            }

            const fontSize = preview ? 15 : 70;
            ctx.font = `${fontSize}px roboto`;
            const lineWidthStroke = preview ? 5 : 20;
            ctx.lineWidth = lineWidthStroke;

            for (let i = 0; i < imgPrediction.b_box.length; i++) {
                const bbox = imgPrediction.b_box[i];
                const x1 = bbox[0][0] * multiplier;
                const y1 = bbox[0][1] * multiplier;
                const x2 = bbox[1][0] * multiplier;
                const y2 = bbox[1][1] * multiplier;

                ctx.beginPath();
                ctx.rect(x1, y1, x2 - x1, y2 - y1);
                ctx.strokeStyle = 'red';
                ctx.stroke();

                const conf = (imgPrediction.conf_list[i] * 100).toFixed(0);
                const confText = `${imgPrediction.label_list?.[i]} ${conf}%`;

                ctx.fillStyle = 'red';
                const fillWidth = ctx.measureText(confText).width + 20;
                const fillHeight = preview ? 15 : 70;
                ctx.fillRect(x1 - (preview ? 2 : 10), y1 - fillHeight, fillWidth, fillHeight);

                ctx.fillStyle = 'white';
                ctx.fillText(confText, x1 + 5, y1 - (preview ? 3 : 15));
            }

            try {
                const imageUrl = canvasRef.current.toDataURL('image/png', 1.0);
                setCanvasImage(imageUrl);
            } catch (error) {
                console.error('Error creating data URL:', error);
                setCanvasImage(null);
            }
        } catch (error) {
            console.error('Canvas drawing error:', error);
            setCanvasImage(null);
        }
    }

    const getBlobUrl = (blobName: string) => {
        if (!storageAccess?.token || !blobName || !storageAccess.storageName) return null;
        return storageAccess.token.replace('/?', `/${storageAccess.storageName}/${blobName}?`);
    }

    return (
        <div className="rounded-md mt-4 absolute w-full">
            <canvas
                ref={canvasRef}
                style={{ display: 'none' }}
            ></canvas>
            <div className="grid grid-rows-11 gap-2 h-[calc(100vh-100px)]">
                <div className="row-span-9 overflow-hidden rounded-xl relative">
                    {reviewMainImageLoading && (
                        <Skeleton
                            width="100vw"
                            height="100vh"
                            borderRadius="16px"
                        ></Skeleton>
                    )}
                    {!reviewMainImageLoading && mainImage && (
                        <ImageView
                            ref={imageRef}
                            toolbar
                            className="object-cover h-full w-full rounded-xl"
                            imageClassName="object-cover w-full rounded-xl"
                            src={maskImage ? (canvasImage ? canvasImage : mainImage.src) : mainImage.src}
                        />
                    )}
                    {/* AI Processing Loading Indicator */}
                    {isAILoading && (
                        <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full flex items-center space-x-2">
                            <span className="text-sm font-medium">AI</span>
                            <div className="relative flex items-center justify-center">
                                {/* Center dot */}
                                <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />

                                {/* Expanding rings */}
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping" />
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping delay-150" />
                            </div>
                        </div>
                    )}
                    <FeedbackButtons />
                </div>
                <div className="row-span-2 pl-2 w-full h-full overflow-y-scroll">
                    <ReviewThumbs />
                </div>
            </div>
        </div>
    )
}
