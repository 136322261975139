// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getComments,
    getReviewData,
    RootState,
    setMaskImage,
    setReviewMainImageLoading
} from '@/store'
import ReviewTitle from './ReviewTitle'
import ReviewRightPanel from './ReviewRightPanel'
import ReviewMap from './ReviewMap'
import ReviewImage from './ReviewImage'
import Button from '@/components/ui/Button'
import { HiEye, HiEyeOff } from 'react-icons/hi'

const ReviewView = () => {
    const dispatch = useDispatch()

    const { reviewShow, maskImage, reviewData } = useSelector(
        (state: RootState) => state.review,
    )

    useEffect(() => {
        if (reviewData?.assetDetail && reviewShow) {
            dispatch(getComments({ asset: reviewData?.assetDetail, imageId: null }))
        }
    }, [reviewData, reviewShow])


    return (
        <div
            className={`${
                !reviewShow
                    ? 'hidden'
                    : 'w-full md:h-[100%] h-max absolute bg-antiFlashWhite z-20 font-roboto'
            }`}
        >
            <ReviewTitle />
            <div className="grid grid-cols-11 gap-2">
                <div className="col-span-2 pl-2">
                    <ReviewMap />
                </div>
                <div className="col-span-6 relative">
                    <ReviewImage />
                    <div className="absolute top-3 left-2 md:left-1/2 transform md:-translate-x-1/2 new-defect-image-header">
                        <div className="gap-2 flex">

                                <Button
                                    variant="filled"
                                    size="xs"
                                    icon={
                                        maskImage ? (
                                            <HiEye className="text-md" />
                                        ) : (
                                            <HiEyeOff className="text-md" />
                                        )
                                    }
                                    onClick={() =>
                                        dispatch(setMaskImage(!maskImage))
                                    }
                                />

                        </div>
                    </div>
                </div>
                <div className="col-span-3">
                    <ReviewRightPanel />
                </div>
            </div>
        </div>
    )
}

export default ReviewView
