// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useState, forwardRef } from 'react'
import { Mention } from 'primereact/mention'
import { Button } from '@/components/ui/Button'
import { VscSend } from 'react-icons/vsc'

export type UserNameDto = {
    email: string
    userName: string
}

type MentionInputProps = {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    loading: boolean
    onSubmit: () => void
    placeholder?: string
    userNames: UserNameDto[]
}

const MentionInput = forwardRef<HTMLDivElement, MentionInputProps>(
    ({ value, onChange, loading, onSubmit, placeholder, userNames }, ref) => {
        const [suggestions, setSuggestions] = useState<UserNameDto[]>([])

        const itemTemplate = (suggestion: UserNameDto) => (
            <div className="flex align-items-center">
                <span className="flex flex-column">
                    <span>@{suggestion.userName}</span>
                </span>
            </div>
        )

        const onSearch = (event: { query: string }) => {
            // Simulate API request with a delay
            setTimeout(() => {
                const query = event.query.trim().toLowerCase()
                const newSuggestions = query
                    ? userNames.filter((userName) =>
                          userName.userName.toLowerCase().startsWith(query),
                      )
                    : [...userNames]

                setSuggestions(newSuggestions)
            }, 250)
        }

        return (
            <div className="relative flex-grow">
                {/* Mention component for user mentions in comments */}
                <Mention
                    ref={ref}
                    placeholder={placeholder}
                    inputClassName="w-full border border-gray-200 no-focus-outline p-2 rounded-md pr-7 font-roboto h-[46px] text-gray-900"
                    className="w-full"
                    value={value}
                    suggestions={suggestions}
                    field="userName"
                    itemTemplate={itemTemplate}
                    onSearch={onSearch}
                    onChange={onChange}
                />
                {/* Button for sending a comment */}
                <Button
                    className={`!rounded-full border-none absolute right-1 top-1/2 transform -translate-y-1/2 shadow-none w-[30px] h-[30px] ${value?.length ? 'bg-trueBlue text-white' : '!bg-antiFlashWhiteGray'}`}
                    disabled={loading || !value}
                    icon={
                        <VscSend
                            className={`text-sm ${value?.length ? 'text-white' : ''}`}
                        />
                    }
                    variant="custom"
                    onClick={onSubmit}
                />
            </div>
        )
    },
)

MentionInput.displayName = "MentionInput";

// Memoized export for performance optimization
export default memo(MentionInput)
