// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useState} from 'react';
import {UserNameDto} from "@/views/dashboard/DashboardView";
import {ScrollPanel} from "primereact/scrollpanel";
import {Mention} from "primereact/mention";
import {HiUserCircle} from "react-icons/hi";
import {Button} from "@/components/ui";
import {VscSend} from "react-icons/vsc";
import {useDispatch, useSelector} from "react-redux";
import {createComment, RootState, useAppSelector} from "@/store";
import CommentItemTab from "@/modules/review-2/review/Comment/CommentItemTab";

const CommentsTab = () => {
    const dispatch = useDispatch()
    const {
        reviewData,
        selectedImageData,
    } = useSelector((state: RootState) => state.review)

    const { comments, loading } =
        useSelector((state: RootState) => state.comment)

    const usernames = useAppSelector(state => state.global.users)

    const [message, setMessage] = useState('')
    const [showReplyId, setShowReplyId] = useState(null)
    const [suggestions, setSuggestions] = useState<UserNameDto[]>([])
    const [showAllReplies, setShowAllReplies] = useState(false)

    const toggleReplies = (comment) => {
        setShowReplyId(comment?.id)
        setShowAllReplies(!showAllReplies)
    }

    function handleCreateComment() {
       setMessage('')
       dispatch(createComment({ asset: reviewData?.assetDetail, imageId: selectedImageData?.id , message }))
    }

    const onSearch = (event) => {
        setTimeout(() => {
            const query = event.query
            let newSuggestions
            if (!query.trim().length) {
                newSuggestions = [...usernames]
            } else {
                newSuggestions = usernames.filter((userName) => {
                    return userName.userName
                        .toLowerCase()
                        .startsWith(query.toLowerCase())
                })
            }

            setSuggestions(newSuggestions)
        }, 250)
    }

    const itemTemplate = (suggestion) => {
        return (
            <div className="flex align-items-center">
                <span className="flex flex-column">
                    <span>{suggestion.userName}</span>
                </span>
            </div>
        )
    }

    const renderComment = () => {
        return comments?.map((comment) => (
            <CommentItemTab
                key={comment.id}
                comment={comment}
                userNames={usernames}
                showAllReplies={showAllReplies}
                showReplyId={showReplyId}
                setShowReplyId={setShowReplyId}
                toggleReplies={toggleReplies}
            />
        ))
    }

    return (
        <div className="flex rounded-lg bg-white border-l border-chineseWhite w-full mt-4">
            <div className="flex flex-col py-4 px-2 w-full h-[calc(100vh-180px)]">
                <div className="flex flex-col w-full h-full overflow-y-scroll">
                    <ScrollPanel className="w-full">
                        <div className="flex flex-col space-y-2">
                            {renderComment()}
                        </div>
                    </ScrollPanel>
                </div>
                <div className="p-inputgroup flex mt-auto w-full pt-1">
                    <HiUserCircle className="mr-1 mt-5 h-[30px] w-[30px]"/>
                    <div className="relative flex-grow">
                        <Mention
                            inputClassName="bg-gray-200 no-focus-outline p-2 rounded-md pr-14 font-roboto"
                            rows={3}
                            className="w-full"
                            value={message}
                            suggestions={suggestions}
                            field="userName"
                            itemTemplate={itemTemplate}
                            onSearch={onSearch}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button
                            className="border-none absolute right-1 top-1/2 transform -translate-y-1/2 shadow-none"
                            disabled={loading || !message}
                            icon={<VscSend/>}
                            variant="transparent"
                            onClick={handleCreateComment}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentsTab;
