// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import classNames from 'classnames'
import { HiOutlineBell } from 'react-icons/hi'
import SidePanelContent, { SidePanelContentProps } from './SidePanelContent'
import withHeaderItem from '@/utils/hoc/withHeaderItem'
import { setPanelExpand, useAppSelector, useAppDispatch, fetchNotifications } from '@/store'
import type { CommonProps } from '@/@types/common'
import { Badge } from '@/components/ui'
import { useEffect, useState, useRef } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';

type SidePanelProps = SidePanelContentProps & CommonProps

const _SidePanel = (props: SidePanelProps) => {
    const [loading] = useState(false);
    const notifications = useAppSelector((state) => state.notification.notifications);
    const panelExpand = useAppSelector((state) => state.global.panelExpand);
    const op = useRef(null);

    const dispatch = useAppDispatch();

    const { className, ...rest } = props;

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    const getNotifications = () => {
        dispatch(fetchNotifications());
    };

    const closePanel = () => {
        if (op.current) {
            op.current.hide();
        }
        
        dispatch(setPanelExpand(false));
        const bodyClassList = document.body.classList;
        if (bodyClassList.contains('drawer-lock-scroll')) {
            bodyClassList.remove('drawer-lock-scroll', 'drawer-open');
        }
    };

    return (
        <>
            <div
                className={classNames('text-2xl', className)}
                onClick={(e) => {
                    op.current.toggle(e);
                    getNotifications();
                }}
                {...rest}
            >
                {notifications.filter(val => !val.read).length > 0 ?
                <Badge badgeStyle={{ top: '3px', right: '6px' }}>
                    <HiOutlineBell />
                </Badge>
                    :
                    <HiOutlineBell />
                }
            </div>
            <OverlayPanel 
                ref={op} 
                showCloseIcon 
                unstyled
                onHide={closePanel}
            >
                <SidePanelContent
                    panelExpand={panelExpand}
                    callBackClose={closePanel}
                    loading={loading}
                    notifications={notifications}
                    getNotifications={getNotifications} />
            </OverlayPanel>
        </>
    );
};

const SidePanel = withHeaderItem(_SidePanel)

export default SidePanel
