// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { HiUserCircle } from 'react-icons/hi'
import OverlayCommentOptions from './OverlayCommentOptions'
import MentionInput from '@/modules/review-2/review/Comment/MentionInput'
import ReplyItemTab from '@/modules/review-2/review/Comment/ReplyItemTab'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteComment,
    RootState,
    setEditCommentId,
    updateComment, useAppSelector,
} from '@/store'


const CommentHeader = ({ comment, onDelete, onEdit }) => {
    const { reviewData } = useAppSelector(state => state.review);
    const user = reviewData?.user;

    return (
        <div className="flex flex-row">
            <HiUserCircle
                style={{ height: '24px', width: '24px' }}
                className="flex-shrink-0 h-[24px] w-[24px]"
            />
            <div className="mb-1 flex flex-col ml-1 font-roboto content-center mt-1 grow">
                <span className="font-semibold text-gray-800 mb-1 text-xs">
                    {comment.createdBy.name}
                </span>
                <span className="text-gray-500 text-xs">
                    {dayjs(comment.commentTime).format('DD/MM/YYYY HH:mm')}
                </span>
            </div>
            {comment?.createdBy?.id === user?.id && (
                <div>
                    <OverlayCommentOptions
                        onDelete={onDelete}
                        onEdit={onEdit}
                    />
                </div>
            )}
        </div>
    )
}

const RepliesToggle = ({
    comment,
    toggleReplies,
    showAllReplies,
    showReplyId,
}) => (
    <div className="flex my-2.5 ml-6 items-center">
        <div className="w-[32px] h-[1px] bg-silverSand mr-1.5"></div>
        <button
            className="text-silverSand text-xs"
            onClick={() => toggleReplies(comment)}
        >
            {showAllReplies && comment?.id === showReplyId
                ? 'View less reply'
                : 'View more reply'}
        </button>
    </div>
)

const CommentBody = ({
    comment,
    editCommentId,
    updateMessageInput,
    goToImage,
}) => {
    return (
        <div className="flex-1 ml-7 mt-2.5">
            <p
                className={`m-0 font-roboto text-sm break-all ${
                    comment.label ? 'hover:underline cursor-pointer' : ''
                }`}
                onClick={() => {
                    if (comment.label && editCommentId !== comment?.id)
                        goToImage(comment)
                }}
            >
                {editCommentId !== comment?.id
                    ? comment.message
                          .split('\n')
                          .map((text, textIndex) => (
                              <React.Fragment key={textIndex}>
                                  {text}
                              </React.Fragment>
                          ))
                    : updateMessageInput()}
            </p>
        </div>
    )
}

const CommentItemTab = ({
    comment,
    goToImage,
    toggleReplies,
    showAllReplies,
    showReplyId,
    setShowReplyId,
    userNames
}) => {
    const dispatch = useDispatch()

    const { editCommentId } = useSelector((state: RootState) => state.comment)
    const { reviewData, selectedImageData } = useSelector(
        (state: RootState) => state.review,
    )

    const [textMessage, setTextMessage] = useState()

    const updateMessageInput = useCallback(() => {
        return (
            <MentionInput
                value={textMessage}
                placeholder="Update comment"
                userNames={userNames}
                onSubmit={() => {
                    dispatch(
                        updateComment({
                            id: editCommentId,
                            message: textMessage,
                            coordinate: comment.label,
                            asset: reviewData?.assetDetail,
                            imageId: selectedImageData?.id,
                        }),
                    )
                    dispatch(setEditCommentId(null))
                }}
                onChange={(e) => setTextMessage(e.target.value)}
            />
        )
    }, [textMessage, userNames])

    const handleEdit = () => {
        setTextMessage(comment?.message)
        dispatch(setEditCommentId(comment?.id))
    }

    const getReply =
        showReplyId && comment?.id ? comment?.id === showReplyId : false

    const repliesToShow = comment.replies.slice(
        0,
        showAllReplies && getReply ? comment.replies.length : 1,
    )

    return (
        <div>
            <div className="max-w-full min-h-[99px] flex-shrink-0 rounded-lg bg-brightGray p-2">
                <CommentHeader
                    comment={comment}
                    onDelete={() =>
                        dispatch(
                            deleteComment({
                                commentId: comment?.id,
                                asset: reviewData?.assetDetail,
                                imageId: selectedImageData?.id,
                            }),
                        )
                    }
                    onEdit={handleEdit}
                />
                <CommentBody
                    comment={comment}
                    editCommentId={editCommentId}
                    goToImage={goToImage}
                    updateMessageInput={updateMessageInput}
                />
                {comment?.replies.length
                    ? repliesToShow.map((reply, index) => {
                          return (
                              <ReplyItemTab
                                  key={index}
                                  comment={reply}
                                  parentComment={comment}
                                  parentCommentId={comment.id}
                                  showAllReplies={showAllReplies}
                                  showReplyId={showReplyId}
                                  userNames={userNames}
                                  goToImage={goToImage}
                                  setShowReplyId={setShowReplyId}
                              />
                          )
                      })
                    : null}
            </div>
            {comment.replies?.length > 1 && (
                <RepliesToggle
                    comment={comment}
                    showAllReplies={showAllReplies}
                    showReplyId={showReplyId}
                    toggleReplies={toggleReplies}
                />
            )}
        </div>
    )
}

export default memo(CommentItemTab)
