// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import Map from '@arcgis/core/Map'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import MapView from '@arcgis/core/views/MapView'
import ScaleBar from '@arcgis/core/widgets/ScaleBar'
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle'
import Graphic from '@arcgis/core/Graphic'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export default function ReviewMap() {
    const { selectedTable, reviewShow } = useSelector((state: RootState) => state.review)

    const [view, setView] = useState(null)
    const [graphicsLayer, setGraphicsLayer] = useState(null)

    const mapDiv = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            drawMap()
        }, 100)
    }, [mapDiv, reviewShow])

    useEffect(() => {
        if (view && graphicsLayer) {
            addDefectToMap()
        }
    }, [view, graphicsLayer, selectedTable, reviewShow])

    function drawMap() {
        if (mapDiv.current) {
            const map = new Map({
                basemap: 'streets', // Default basemap
            })
            const newGraphicsLayer = new GraphicsLayer()
            map.add(newGraphicsLayer)

            const newView = new MapView({
                container: mapDiv.current,
                map: map,
                center: [selectedTable?.lon, selectedTable?.lat],
                zoom: 17,
                constraints: {
                    snapToZoom: false,
                },
            })

            // Add the BasemapToggle widget to the view
            const basemapToggle = new BasemapToggle({
                view: newView,
                nextBasemap: 'satellite', // Specify the next basemap
            })
            newView.ui.add(basemapToggle, 'bottom-right')

            const scalebar = new ScaleBar({
                view: newView,
            })
            newView.ui.add(scalebar, 'bottom-left')

            setView(newView)
            setGraphicsLayer(newGraphicsLayer)
        }
    }

    function getColor(defect) {
        let color = 'red'
        if (defect?.priority == 2) color = 'orange'
        if (defect?.priority == 3) color = 'yellow'
        if (defect?.priority == 4) color = 'gray'
        return color
    }

    function addDefectToMap() {
        graphicsLayer.removeAll()
        if (
            !Number.isNaN(selectedTable?.lon) &&
            !Number.isNaN(selectedTable?.lat)
        ) {
            const point = {
                //Create a point
                type: 'point',
                longitude: selectedTable?.lon,
                latitude: selectedTable?.lat,
            }
            const simpleMarkerSymbol = {
                type: 'simple-marker',
                color: getColor(selectedTable),
                outline: {
                    color: [255, 255, 255], // White
                    width: 1,
                },
            }

            const pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
            })
            graphicsLayer.add(pointGraphic)
        }
    }

    return (
        <div className="bg-white border border-chineseWhite w-full h-[calc(100vh-100px)] p-0.5 rounded-lg mt-4">
            <div ref={mapDiv} style={{ width: '100%', height: '100%' }} />
        </div>
    )
}
