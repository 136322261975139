// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useRef} from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { FaRegTrashAlt } from 'react-icons/fa'
import { OverlayPanel } from 'primereact/overlaypanel'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Button } from '@/components/ui/Button'

const OverlayCommentOptions = ({ onEdit, onDelete }) => {
    const op = useRef(null)

    const handleEdit = () => {
        if (op?.current?.hide) {
            op.current.hide();
        }
        if (typeof onEdit === 'function') onEdit()
    };

    const handleDelete = () => {
        if (op?.current?.hide) {
            op.current.hide();
        }
        if (typeof onDelete === 'function') onDelete()
    };

    return (
        <>
            <Button
                type="button"
                className="border-none !bg-transparent h-[25px]"
                icon={<BsThreeDotsVertical className="text-sm" />}
                label="Image"
                onClick={(e) => op.current.toggle(e)}
            />
            <OverlayPanel ref={op} className="p-0 m-0 custom-overlay-panel" >
                <div className="font-roboto">
                    <div className="w-full hover:bg-gray-200">
                        <div
                            className="cursor-pointer px-3 py-2 flex items-center gap-2 text-base"
                            onClick={handleEdit}
                        >
                            <MdOutlineEdit className="text-md" />
                            Edit
                        </div>
                    </div>
                    <div className="w-full hover:bg-gray-200">
                        <div
                            className="cursor-pointer px-3 py-2 flex items-center gap-2 text-base"
                            onClick={handleDelete}
                        >
                            <FaRegTrashAlt className="text-md" />
                            Delete
                        </div>
                    </div>
                </div>
            </OverlayPanel>
        </>
    )
}

export default OverlayCommentOptions
